import { useMutation, useQuery, useQueryClient } from "react-query";
import { Axios } from "../../../Api/axios";
import { BANNER } from "../../../Api/api";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import TableShow from "../../../Components/Dashboard/Table/Table";
import { useState } from "react";

export default function Banner() {
  const header = [
    {
      key: "title_en",
      name: "العنوان En",
    },
    {
      key: "title_ar",
      name: "العنوان Ar",
    },
    {
      key: "img",
      name: "صورة البنر ",
    },
  ];

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryFn: () => Axios.get(`${BANNER}`),
    queryKey: ["dashImages"],
    onSuccess: () => {},
  });
  const { isLoading: addLoading, mutateAsync: addImages } = useMutation({
    mutationFn: async (data) => await Axios.post(`${BANNER}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashImages"]);
    },
  });
  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: async (id) => await Axios.delete(`${BANNER}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashImages"]);
    },
  });

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center p-3">
        <h2 className="line">تعديل بيانات البنر</h2>
        <Link to={"/dashboard/banner/add"}>
          <Button disabled={addLoading} variant="primary">
            إضافة صور جديدة
          </Button>
        </Link>
      </div>

      <div className="m-3">
        <TableShow
          best
          action
          actionEdit
          header={header}
          data={data?.data}
          searchItem="title_en"
          delete={mutateAsync}
          deleteLoading={isLoading}
          isSuccess={isSuccess}
          showDublicate={false}
        />
      </div>
    </div>
  );
}
