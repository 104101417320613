import { createContext, useState } from "react";

export const Side = createContext("");

export default function SideBar({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Side.Provider value={{ isOpen, setIsOpen }}>{children}</Side.Provider>
  );
}
