import { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Lang } from "../../../Context/LangContext";
import { Axios } from "../../../Api/axios";
import { Link } from "react-router-dom";
import { GetPayment } from "../../../Api/api";
import Loading from "../../../Components/Loading/Loading";

export default function Transaction() {
  const [infoPay, setInfoPay] = useState({});
  const [errorMessage, setErrorMessage] = useState(true);
  const [loading, setLoading] = useState(true);
  const { lang } = useContext(Lang);
  const typography = lang === "ar" ? "f-cairo" : "f-work-sans";
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    Axios.get(`${GetPayment}?id=${id}`)
      .then((res) => {
        setInfoPay(res?.data);
        setErrorMessage(res?.data?.errorMessage);
        setLoading(false);
        if (res?.data?.resultObj?.status === "paid") {
          localStorage.removeItem("products");
          localStorage.removeItem("productsIds");
          localStorage.removeItem("colorIds");
          localStorage.removeItem("quantity");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading ? (
        <div className="bg-custom-grey h-100vh">
          <Loading />
        </div>
      ) : (
        <div
          style={{ paddingTop: "100px", paddingBottom: "100px" }}
          className={`bg-custom-grey d-flex aling-items-center justify-content-center h-100vh ${typography}`}
        >
          <Container>
            <div
              className={`d-flex aling-items-center justify-content-center h-100`}
            >
              {
                <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                  <h3 className="text-center">
                    {infoPay?.resultObj?.status === "paid"
                      ? lang === "en"
                        ? "The payment was completed successfully"
                        : "تمت عملية الدفع بنجاح"
                      : lang === "en"
                      ? "There was a problem during the payment process"
                      : "حصلت مشكلة اثناء عملية الدفع"}
                  </h3>
                  <Link
                    to={
                      infoPay?.resultObj?.status === "paid" ? "/" : "/checkout"
                    }
                    className="col-12 col-md-6 text-white text-decoration-none d-flex justify-content-center"
                  >
                    <Button className="bg-custom-primary border-0">
                      {infoPay?.resultObj?.status === "paid"
                        ? lang === "en"
                          ? "Go to the home page"
                          : "التوجه الى الصفحة الرئيسية"
                        : lang === "en"
                        ? "Go To Checkout Page"
                        : "العودة لصفحة الدفع"}
                    </Button>
                  </Link>
                </div>
              }
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
