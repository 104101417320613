import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Lang } from "../../../Context/LangContext";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import { Axios } from "../../../Api/axios";
import { CONTACT } from "../../../Api/api";
export default function Contact() {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    title: "",
    message: "",
    lang: "",
  });
  const [flag, setFlag] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  // Lang
  const language = useContext(Lang);
  const lang = language.lang;
  const typography = lang === "ar" ? "f-cairo" : "f-work-sans";

  useEffect(() => {
    setForm({ ...form, lang: lang });
  }, [lang]);
  // Handle Change Input
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        (emailRegex.test(form.email) && form.phone.length === 0) ||
        (emailRegex.test(form.email) && form.phone.length === 8)
      ) {
        const res = await Axios.post(`${CONTACT}`, form);
        setLoading(false);
        setAlert(true);
        setForm({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          title: "",
          message: "",
          lang: "",
        });
        setLoading(false);
        setFlag(false);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
      } else {
        setFlag(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }

  // Handle scroll in input
  const inputRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef.current;

    inputElement.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      inputElement.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div
      style={{ paddingTop: "100px", paddingBottom: "calc(100px + 2rem)" }}
      className={`bg-custom-grey ${typography} position-relative`}
    >
      <Container>
        <div dir={lang === "en" ? "ltr" : "rtl"} className="bg-white p-5">
          <p className="fs-2 fw-bold">
            {lang === "en" ? "Conatct Us" : "تواصل معنا"}
          </p>
          <div>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex align-items-center justify-content-between flex-wrap ">
                <div className="mb-3 col-12 col-md-6">
                  <Form.Group
                    className={`${
                      lang === "en" ? "me-md-3 me-0" : "ms-md-3 ms-0"
                    }`}
                    controlId="formBasicFirstName"
                  >
                    <Form.Label>
                      {lang === "en" ? "First Name :" : "الأسم الأول :"}
                    </Form.Label>
                    <Form.Control
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder={
                        lang === "en"
                          ? "Write First Name "
                          : "ادخل الأسم الأول  "
                      }
                      value={form.first_name}
                      name="first_name"
                    />
                  </Form.Group>
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <Form.Group controlId="formBasicLastName">
                    <Form.Label>
                      {lang === "en" ? "last Name :" : "الأسم الأخير :"}
                    </Form.Label>
                    <Form.Control
                      required
                      onChange={handleChange}
                      type="text"
                      placeholder={
                        lang === "en"
                          ? "Write Last Name "
                          : "ادخل الأسم الأخير  "
                      }
                      value={form.last_name}
                      name="last_name"
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  {lang === "en" ? "Email :" : "البريد الإلكتروني :"}
                </Form.Label>
                <Form.Control
                  required
                  onChange={handleChange}
                  type="email"
                  placeholder={
                    lang === "en" ? "Write Email " : "ادخل البريد الإلكتروني"
                  }
                  value={form.email}
                  name="email"
                />
                {flag && !emailRegex.test(form.email) && (
                  <p
                    className={`position-absolute top-100 ${
                      lang === "en" ? "start-0" : "end-0"
                    } text-danger`}
                  >
                    {lang === "en"
                      ? "please enter a working email address"
                      : " الرجاء إدخال بريد الكتروني صحيح"}
                  </p>
                )}
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  {lang === "en" ? "Mobile Phone :" : " رقم الهاتف :"}
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  type="number"
                  ref={inputRef}
                  placeholder={
                    lang === "en" ? "Write Mobile Number " : "ادخل رقم الهاتف"
                  }
                  value={form.phone}
                  name="phone"
                />
                {flag && form?.phone?.length !== 8 && (
                  <p
                    className={`position-absolute top-100 ${
                      lang === "en" ? "start-0" : "end-0"
                    } text-danger`}
                  >
                    {lang === "en"
                      ? "Please enter an eight-digit phone number"
                      : " الرجاء ادخال رقم هاتف مكون من 8 خانات"}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {lang === "en" ? "Title Message :" : " عنوان الرسالة :"}
                </Form.Label>
                <Form.Control
                  required
                  onChange={handleChange}
                  type="text"
                  placeholder={
                    lang === "en"
                      ? "Write Title Message "
                      : "ادخل عنوان الرسالة "
                  }
                  value={form.title}
                  name="title"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                <Form.Label>
                  {lang === "en" ? "Message :" : "الرسالة :"}
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  onChange={handleChange}
                  type="text"
                  placeholder={lang === "en" ? "Write Message " : "الرسالة... "}
                  value={form.message}
                  name="message"
                />
              </Form.Group>
              {loading ? (
                <div
                  style={{ width: "86px", height: "37px" }}
                  className="bg-main-color py-3 rounded d-flex align-items-center justify-contnet-center"
                >
                  <LoadingBtn />
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <Button className="bg-main-color" type="submit">
                    {lang === "en" ? "Send" : "إرسال "}
                  </Button>
                  {alert && (
                    <Alert
                      className="fit-content mb-0"
                      variant={"success"}
                      key={"success"}
                    >
                      {lang === "en"
                        ? "The message was sent successfully"
                        : "تم ارسال الرسالة بنجاح"}
                    </Alert>
                  )}
                </div>
              )}
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
