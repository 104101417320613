import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import { WindowSize } from "../../../Context/WindowContext";

import { Side } from "../../../Context/SideBarContext";
import { Lang } from "../../../Context/LangContext";
import "./navbar.css";
import ModalShoping from "./ModalShoping";

export default function NavBar() {
  const WindowContext = useContext(WindowSize);
  const windowSize = WindowContext.windowSize;
  const menu = useContext(Side);
  const isOpen = menu.isOpen;
  const setIsOpen = menu.setIsOpen;
  // Lang
  const language = useContext(Lang);

  return (
    <>
      <Navbar
        id="navbar"
        className={` bg-white position-fixed top-0 start-0 w-100 ${
          language.lang === "ar" ? "f-cairo" : "f-work-sans"
        }`}
        style={{ zIndex: "3", boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.08)" }}
      >
        {windowSize > 992 ? (
          <Container>
            <Link
              to={"/"}
              className="text-decoration-none text-black d-flex align-items-center"
              style={{ fontSize: "38px", fontWeight: "500" }}
            >
              <img
                width={"150px"}
                src={require("../../../Assets/Images/Logo.png")}
                alt="logo"
              />
            </Link>
            {language.lang === "en" ? (
              <Nav
                style={{ height: "72px" }}
                className="d-flex align-items-center gap-4 act"
              >
                <NavLink
                  className={
                    "nav-link d-flex align-items-center h-100 position-relative text-decoration-none fit-content text-center px-1"
                  }
                  to="/"
                >
                  Home
                </NavLink>
                <NavLink
                  className={
                    "nav-link d-flex align-items-center h-100 position-relative text-decoration-none fit-content text-center px-1"
                  }
                  to="/about"
                >
                  About Us
                </NavLink>
                <NavLink
                  className={
                    "nav-link d-flex align-items-center h-100 position-relative text-decoration-none fit-content text-center px-1"
                  }
                  to="/contact"
                >
                  Contact Us
                </NavLink>
                <ModalShoping />
              </Nav>
            ) : (
              <Nav
                style={{ height: "72px" }}
                className="d-flex align-items-center gap-4 flex-row-reverse act"
              >
                <ModalShoping />
                <NavLink
                  className={
                    "nav-link d-flex align-items-center h-100 position-relative text-decoration-none fit-content text-center px-1"
                  }
                  to="/"
                >
                  الصفحة الرئيسية
                </NavLink>
                <NavLink
                  className={
                    "nav-link d-flex align-items-center h-100 position-relative text-decoration-none fit-content text-center px-1"
                  }
                  to="/about"
                >
                  من نحن
                </NavLink>
                <NavLink
                  className={
                    "nav-link d-flex align-items-center h-100 position-relative text-decoration-none fit-content text-center px-1"
                  }
                  to="/contact"
                >
                  تواصل معنا
                </NavLink>
              </Nav>
            )}
          </Container>
        ) : (
          <Container>
            <Link
              to={"/"}
              className="text-decoration-none text-black d-flex align-items-center"
              style={{ fontSize: "38px", fontWeight: "500" }}
            >
              <img
                width={"150px"}
                src={require("../../../Assets/Images/Logo.png")}
                alt="logo"
              />
            </Link>
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-2">
                <ModalShoping />
                <div className="position-relative ms-4">
                  <FontAwesomeIcon
                    fontSize={28}
                    onClick={() => setIsOpen((prev) => !prev)}
                    cursor={"pointer"}
                    icon={faBars}
                  />
                  <div className="sideBar">
                    <div
                      style={{
                        position: "fixed",
                        top: "70px",
                        right: "0",
                        width: "100%",
                        height: "100vh",
                        zIndex: 1,
                        backgroundColor: "rgba(0,0,0,0.2)",
                        display:
                          windowSize < "992" && isOpen ? "block" : "none",
                      }}
                    ></div>
                    <div
                      className="side-bar pt-3"
                      style={{
                        right: isOpen ? 0 : "-100%",
                        top: "70px",
                        width: windowSize < 427 ? "100%" : "220px",
                        position: "fixed",
                        transition: "0.3s",
                        backgroundColor: "#ECF0F3",
                      }}
                    >
                      {language.lang === "en" ? (
                        <Nav className="d-flex align-items-center flex-column  act small-screen">
                          <NavLink
                            className={
                              "nav-link p-3 d-flex align-items-center h-100 position-relative text-decoration-none w-100 text-center"
                            }
                            to="/"
                          >
                            Home
                          </NavLink>
                          <NavLink
                            className={
                              "nav-link p-3 d-flex align-items-center h-100 position-relative text-decoration-none w-100 text-center"
                            }
                            to="/about"
                          >
                            About Us
                          </NavLink>
                          <NavLink
                            className={
                              "nav-link p-3 d-flex align-items-center h-100 position-relative text-decoration-none w-100 text-center"
                            }
                            to="/contact"
                          >
                            Contact Us
                          </NavLink>
                        </Nav>
                      ) : (
                        <Nav className="d-flex align-items-center flex-column act small-screen">
                          <NavLink
                            className={
                              "nav-link p-3 d-flex align-items-center h-100 position-relative text-decoration-none w-100 text-center justify-content-end"
                            }
                            to="/"
                          >
                            الصفحة الرئيسية
                          </NavLink>
                          <NavLink
                            className={
                              "nav-link p-3 d-flex align-items-center h-100 position-relative text-decoration-none w-100 text-center justify-content-end"
                            }
                            to="/about"
                          >
                            من نحن
                          </NavLink>
                          <NavLink
                            className={
                              "nav-link p-3 d-flex align-items-center h-100 position-relative text-decoration-none w-100 text-center justify-content-end"
                            }
                            to="/contact"
                          >
                            تواصل معنا
                          </NavLink>
                        </Nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )}
      </Navbar>
    </>
  );
}
