import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputImage from "../../../Components/Dashboard/Inputs/InputImage";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import { Axios } from "../../../Api/axios";
import { BANNER } from "../../../Api/api";
import { useNavigate } from "react-router-dom";

export default function AddBanner() {
  const [form, setForm] = useState({
    title_ar: "",
    title_en: "",
    image: "",
  });
  const nav = useNavigate("");
  const [loading, setLoading] = useState(false);
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("title_en", form.title_en);
    formData.append("title_ar", form.title_ar);
    formData.append("img", form.image);
    try {
      const res = await Axios.post(`${BANNER}`, formData);
      nav("/dashboard/banner");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  // Habdel Focus on input file
  const openImage = useRef();
  const handleOpenImage = () => {
    openImage.current.click();
  };

  return (
    <div className="w-100">
      <div className=" p-3">
        <h2 className="line">إضافة بنر جديد </h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>نص البنر باللغة العربية :</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_ar}
              name={"title_ar"}
              type="text"
              placeholder={"أدخل نص البنر باللغة العربية..."}
              ref={focus}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>نص البنر باللغة الإنكليزية :</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_en}
              name={"title_en"}
              type="text"
              placeholder={"أدخل نص البنر باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group className="mb-0" controlId="formBasicImage">
            <Form.Label>صورة المنتج :</Form.Label>
            <Form.Control
              ref={(e) => (openImage.current = e)}
              onChange={(e) => setForm({ ...form, image: e.target.files[0] })}
              hidden
              type="file"
            />
          </Form.Group>
          <InputImage
            label="Upload Image"
            getData
            handleOpenImage={() => handleOpenImage()}
          />
          {form.image && (
            <div className="d-flex flex-column align-items-start justify-content-start gap-2 my-3  position-relative">
              <img
                src={
                  typeof form.image === "object"
                    ? URL.createObjectURL(form.image)
                    : form.image
                }
                alt="icon-img"
                width={"20%"}
              />
            </div>
          )}
          {loading ? (
            <div
              style={{ width: "86px", height: "37px" }}
              className="bg-main-color mt-3 py-3 rounded d-flex align-items-center justify-contnet-center"
            >
              <LoadingBtn />
            </div>
          ) : (
            <Button
              disabled={form.image === "" && true}
              className="mt-3"
              variant="primary"
              type="submit"
            >
              {"إضافة البنر "}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}
