import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import AreYouSure from "../AreYouSure/AreYouSure";

export default function CheckoutTableShow(props) {
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [itemId, setItemId] = useState();

  const searchShow = props.search !== undefined ? props.search : true;
  const dateShow = props.date !== undefined ? props.date : true;
  const dataFilter = props.data?.filter((i) =>
    i?.checkout[props.searchItem]
      ?.toLowerCase()
      ?.includes(search?.toLowerCase())
  );
  // Hnadle Delete
  useEffect(() => {
    props.isSuccess && setShowPopUp(false);
  }, [props.isSuccess]);
  // Filter Data
  const data = dataFilter?.filter((item) =>
    item?.checkout?.created_at?.includes(date)
  );

  const headerShow = props.header.map((item, key) => (
    <th key={key}>{item?.name}</th>
  ));
  const dataShow = data?.map((item, key) => (
    <tr key={key}>
      <td>{key + 1}</td>
      {props.header.map((item2, key2) => (
        <td
          style={{
            maxWidth: item2.key === "full_address" ? "0" : "fit-content",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          key={key2}
        >
          {item2?.name === "الساعة"
            ? new Date(item.checkout[item2.key]).getHours() +
              ":" +
              new Date(item.checkout[item2.key]).getMinutes() +
              ":" +
              new Date(item.checkout[item2.key]).getSeconds()
            : item2.key === "created_at"
            ? new Date(item.checkout[item2.key]).toLocaleDateString()
            : item.checkout[item2.key]}
        </td>
      ))}
      <td>
        <div className="d-flex align-items-center justify-content-center gap-2">
          <FontAwesomeIcon
            onClick={() => {
              setShowPopUp(true);
              setItemId(item.checkout.id);
            }}
            cursor={"pointer"}
            fontSize={"19px"}
            color="red"
            icon={faTrash}
          />
          <Link to={`${item.checkout.id}`}>
            <FontAwesomeIcon
              cursor={"pointer"}
              fontSize={"19px"}
              color="#0D6EFD"
              icon={faEye}
            />
          </Link>
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <AreYouSure
        title={
          props.showDublicate
            ? "هذا الإجراء سيحذف المنتج ولا يمكن التراجع عنه"
            : "هذا الإجراء سيحذف البنر ولا يمكن التراجع عنه"
        }
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
        handleOperation={() => {
          props.delete(itemId);
        }}
        loading={props.deleteLoading}
      />
      <div className="d-flex flex-wrap justify-content-between gap-3">
        {dateShow && (
          <Form.Group
            className="mb-3 col-12 col-sm-4 "
            controlId="formBasicCategories"
          >
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
        )}
        {searchShow && (
          <Form.Group
            className="mb-3 col-12 col-sm-4 "
            controlId="formBasicCategorie"
          >
            <Form.Control
              type="search"
              value={search}
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form.Group>
        )}
      </div>
      <div style={{ overflow: "auto" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>id</th>
              {headerShow}
              <th>حذف الطلب</th>
            </tr>
          </thead>
          <tbody>{dataShow}</tbody>
        </Table>
      </div>
    </>
  );
}
