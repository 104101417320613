import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Axios } from "../../../Api/axios";
import { COUPON } from "../../../Api/api";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TableShow from "../../../Components/Dashboard/Table/Table";
import { Link } from "react-router-dom";

export default function Copouns() {
  const [form, setForm] = useState({
    name_en: "",
    name_ar: "",
    coupon: "",
    discount: "",
    expires_at: "",
  });
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState("");
  useEffect(() => {
    // الحصول على تاريخ اليوم
    const today = new Date().toISOString().split("T")[0];
    // تعيين قيمة اليوم كقيمة الحد الأدنى لحقل التاريخ
    setMinDate(today);
  }, []);
  // Handle Hange Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const queryClient = useQueryClient();
  // Handle Get Coupons
  const { data } = useQuery({
    queryFn: () => Axios.get(`${COUPON}`),
    queryKey: ["dashCoupon"],
  });
  // Handle Delete Coupon
  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: async (id) => await Axios.delete(`${COUPON}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashCoupon"]);
    },
  });

  const header = [
    {
      key: "name_en",
      name: "عنوان الكوبون En",
    },
    {
      key: "name_ar",
      name: "عنوان الكوبون Ar",
    },
    {
      key: "coupon",
      name: "رمز الكوبون",
    },
    {
      key: "discount",
      name: "قيمة الحسم",
    },
    {
      key: "expires_at",
      name: "تاريخ انتهاء الكوبون",
    },
    {
      key: "created_at",
      name: "أنشأ بتاريخ",
    },
    {
      key: "updated_at",
      name: "عدل بتاريخ",
    },
  ];
  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center p-3">
        <h2 className="line">صفحة التحكم بالكوبونات </h2>
        <Link to={"/dashboard/coupons/add"}>
          <Button variant="primary">انشاء كوبون جديد</Button>
        </Link>
      </div>
      <div className="m-3">
        <TableShow
          action
          coupon
          actionEdit={true}
          searchItem="name_en"
          header={header}
          data={data?.data}
          delete={mutateAsync}
          deleteLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
}
