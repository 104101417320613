import React from "react";
import ReactDOM from "react-dom/client";
import "./CSS/Components/loading.css";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-image-gallery/styles/css/image-gallery.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import WindowContext from "./Context/WindowContext";
import MenuContext from "./Context/MenuContext";
import SideBar from "./Context/SideBarContext";
import LangContext from "./Context/LangContext";
import { QueryClient, QueryClientProvider } from "react-query";
import CartContext from "./Context/CartContext";
import OnDelivery from "./Context/checkoutContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
const query = new QueryClient();

root.render(
  <React.StrictMode>
    <WindowContext>
      <QueryClientProvider client={query}>
        <LangContext>
          <MenuContext>
            <CartContext>
              <SideBar>
                <OnDelivery>
                  <Router>
                    <App />
                  </Router>
                </OnDelivery>
              </SideBar>
            </CartContext>
          </MenuContext>
        </LangContext>
      </QueryClientProvider>
    </WindowContext>
  </React.StrictMode>
);
