import Container from "react-bootstrap/esm/Container";
import NavBar from "../../../Components/Web/Navbar/Navbar";
import { useQuery } from "react-query";
import { CheckQuantity, PRO } from "../../../Api/api";
import { Axios } from "../../../Api/axios";
import { useParams } from "react-router-dom";
import "./ProductPage.css";
import { useContext, useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import Skeleton from "react-loading-skeleton";
import { Lang } from "../../../Context/LangContext";
import { Cart } from "../../../Context/CartContext";
import ReactElasticCarousel from "@itseasy21/react-elastic-carousel";
import Product from "../../../Components/Web/Product/Product";
import myArrow from "../../../Components/Web/SliderArrows/SliderArrows";
import { Button } from "react-bootstrap";

export default function ProductPage() {
  const [number, setNumber] = useState(1);
  const [colorId, setColorId] = useState(0);
  const [colorIdForSending, setcolorIdForSending] = useState(0);
  const [colorShadow, setColorShadow] = useState(0);
  const [formNotifyMe, setFormNotifyMe] = useState("");
  const [colorName, setColorName] = useState({
    name_ar: "",
    name_en: "",
  });
  const [images, setImages] = useState([]);
  const [checkQuantity, setCheckQuantity] = useState("");
  const [alertMoreQuantity, setAlertMoreQuantity] = useState(false);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 5 },
  ];
  const { setCart, setGetData } = useContext(Cart);
  const { id } = useParams();
  const { lang } = useContext(Lang);

  const typography = lang === "ar" ? "f-cairo" : "f-pop";

  const {
    isLoading,
    isSuccess,
    data: product,
  } = useQuery({
    queryFn: () => Axios.get(`${PRO}/${id}`),
    queryKey: ["SingleProduct", id],
  });

  const { data: products } = useQuery({
    queryFn: () => Axios.get(`${PRO}`),
    queryKey: ["singlePageProducts"],
    staleTime: Infinity,
  });

  const productsShow = products?.data?.map((product, key) => (
    <Product
      key={key}
      id={product.id}
      title={product.title_en}
      title_ar={product.title_ar}
      description={product.description_en}
      description_ar={product.description_ar}
      img={product.colors[0]?.url}
      price={product.price}
      discount={product.discount}
      colors={product.colors}
      className="col-12"
    />
  ));
  const productShow = product?.data[0];
  const smallFilterColor = productShow?.colors?.filter(
    (color, index) => index === colorId && color
  );
  useEffect(() => {
    if (isSuccess) {
      setImages(
        smallFilterColor?.[0]?.product_image?.map((img) => {
          return { original: img?.url, thumbnail: img?.url };
        })
      );
      setcolorIdForSending(productShow.colors[0].id);
    }
    window.scrollTo(0, 0);
  }, [isSuccess, product]);

  function handleChangeColor(key) {
    setImages(() =>
      productShow?.colors
        ?.filter((color, index) => index === key && color)?.[0]
        ?.product_image?.map((img) => {
          return { original: img.url, thumbnail: img.url };
        })
    );
  }
  // Handle Add To Cart
  function handleAddCart() {
    let products = JSON.parse(localStorage.getItem("products")) || [];
    let productsIds = JSON.parse(localStorage.getItem("productsIds")) || [];
    let colorIds = JSON.parse(localStorage.getItem("colorIds")) || [];
    let quantity = JSON.parse(localStorage.getItem("quantity")) || [];

    Axios.get(`${CheckQuantity}/${colorIdForSending}`)
      .then((res) => setCheckQuantity(() => res.data.quantity))
      .catch((err) => console.log(err));

    if (colorIds.length === 0) {
      products.push({
        title_en: productShow.title_en,
        title_ar: productShow.title_ar,
        quantity: number,
        price: productShow.price,
        img: productShow.colors[0].url,
      });
      productsIds.push(productShow.id);
      colorIds.push(colorIdForSending);
      quantity.push(number);
    } else {
      if (
        quantity[colorIds.indexOf(colorIdForSending)] + number <=
          checkQuantity ||
        isNaN(quantity[colorIds.indexOf(colorIdForSending)])
      ) {
        setAlertMoreQuantity(false);
        if (colorIds.includes(colorIdForSending)) {
          quantity[colorIds.indexOf(colorIdForSending)] += number;
          products[colorIds.indexOf(colorIdForSending)].quantity =
            quantity[colorIds.indexOf(colorIdForSending)];
          setCart((prev) => prev - 1);
        } else {
          products.push({
            title_en: productShow.title_en,
            title_ar: productShow.title_ar,
            quantity: number,
            price: productShow.price,
            img: productShow.colors[0].url,
          });
          productsIds.push(productShow.id);
          colorIds.push(colorIdForSending);
          quantity.push(number);
          setCart((prev) => prev - 1);
        }
      } else {
        setAlertMoreQuantity(true);
      }
    }
    localStorage.setItem("products", JSON.stringify(products));
    localStorage.setItem("productsIds", JSON.stringify(productsIds));
    localStorage.setItem("colorIds", JSON.stringify(colorIds));
    localStorage.setItem("quantity", JSON.stringify(quantity));
    setCart((prev) => prev - 1);
  }

  // Handle Notify Me
  async function handleNotifyMe() {}
  return (
    <>
      <NavBar />
      <div
        dir={lang === "ar" && "rtl"}
        style={{ paddingTop: "0px", paddingBottom: "100px" }}
      >
        <Container>
          <div className="bg-white p-lg-5 p-2">
            <div className="d-flex align-items-start justify-content-center flex-wrap row-gap-3">
              <div className="col-md-6 col-12">
                {isLoading ? (
                  <>
                    <Skeleton height={"300px"} />
                    <div className="d-flex  gap-2 w-100">
                      <Skeleton width="100px" height={"80px"} />
                      <Skeleton width="100px" height={"80px"} />
                      <Skeleton width="100px" height={"80px"} />
                      <Skeleton width="100px" height={"80px"} />
                    </div>
                  </>
                ) : (
                  <ReactImageGallery
                    showNav={true}
                    fullscreen
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={images}
                  />
                )}
              </div>
              <div className="col-md-5 col-12 mt-3">
                <div className={`m${lang === "ar" ? "e" : "s"}-md-5`}>
                  <h3 className={typography}>
                    {isLoading ? (
                      <Skeleton />
                    ) : lang === "ar" ? (
                      productShow?.title_ar
                    ) : (
                      productShow?.title_en
                    )}
                  </h3>

                  <p className={`text-secondary ${typography}`}>
                    {isLoading ? (
                      <Skeleton />
                    ) : lang === "ar" ? (
                      productShow?.description_ar
                    ) : (
                      productShow?.description_en
                    )}
                  </p>
                  <div className="my-3 d-flex align-items-center flex-wrap gap-2">
                    {isLoading ? (
                      <>
                        <Skeleton
                          height="40px"
                          width="40px"
                          className="rounded-circle"
                        />{" "}
                        <Skeleton
                          height="40px"
                          width="40px"
                          className="rounded-circle"
                        />
                      </>
                    ) : (
                      <div className="col-12">
                        <p
                          className={`fs-6 ${typography}`}
                          style={{ color: "#858585" }}
                        >
                          {lang === "ar"
                            ? ` اللون  ${
                                colorName.name_ar === ""
                                  ? productShow?.colors[0]?.name_ar
                                  : colorName.name_ar
                              }`
                            : `Color  ${
                                colorName.name_en === ""
                                  ? productShow?.colors[0]?.name_en
                                  : colorName.name_en
                              }`}
                        </p>
                        <div className="d-flex align-items-center gap-3">
                          {productShow?.colors?.map((color, key) => (
                            <div
                              key={key}
                              className="rounded-pill"
                              style={{
                                height: "20px",
                                width: "40px",
                                backgroundColor: color.color,
                                cursor: "pointer",
                                border: "2px solid white",
                                outline: `2px solid ${color.color}`,
                                boxShadow:
                                  key === colorShadow &&
                                  `0px 0px 4px 4px #33A2DB`,
                              }}
                              onClick={() => {
                                handleChangeColor(key);
                                setColorId(key);
                                setcolorIdForSending(color.id);
                                setColorShadow(key);
                                setColorName({
                                  ...colorName,
                                  name_ar: color?.name_ar,
                                  name_en: color?.name_en,
                                });
                              }}
                            ></div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <h4>
                    {isLoading ? (
                      <Skeleton />
                    ) : productShow?.discount !== "null" &&
                      Number(productShow?.discount) !== 0 ? (
                      <>
                        <span
                          className={`${
                            productShow?.discount !== "null" && "fw-light"
                          } text-decoration-line-through text-secondary mx-2`}
                        >
                          {Number(productShow?.price).toFixed()}
                        </span>
                        <p className={`fw-bold price-color d-inline ms-2`}>
                          {Number(productShow?.discount).toFixed()}
                          <span className={`mx-2  ${typography}`}>
                            {lang === "ar" ? "ر.ق" : "QAR"}
                          </span>
                        </p>
                      </>
                    ) : (
                      <p
                        className={`m-0 ${typography}`}
                        dir={lang === "ar" && "rtl"}
                      >
                        {Number(productShow?.price).toFixed() +
                          " " +
                          (lang === "ar" ? "ر.ق" : "QAR")}
                      </p>
                    )}
                  </h4>
                  <div className="border-top border-bottom d-flex align-items-center gap-2 flex-wrap py-4 row-gap-3">
                    <div
                      className="rounded-pill bg-custom-grey-2 py-2 d-flex align-items-center  justify-content-center gap-2 col-md-4 col-lg-3 col-6 "
                      style={{ fontSize: "22px" }}
                    >
                      <p
                        onClick={() => {
                          number > 1 && setNumber((prev) => prev - 1);
                        }}
                        className={`m-0 text-center operations`}
                      >
                        -
                      </p>
                      <p className="m-0 border text-center operations">
                        {number}
                      </p>
                      <p
                        onClick={() =>
                          number < productShow.colors[colorId].quantity &&
                          setNumber((prev) => prev + 1)
                        }
                        className={`m-0 text-center operations`}
                      >
                        +
                      </p>
                    </div>
                    <div
                      className={`rounded-pill bg-custom-red py-2 text-capitalize ${typography}  text-center text-white add-to-cart`}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      {productShow?.quantity !== 0 ? (
                        <p
                          style={{ fontSize: "22px" }}
                          className="m-0"
                          onClick={handleAddCart}
                        >
                          {lang === "ar" ? "إضافة السلة" : "Add To Cart"}
                        </p>
                      ) : (
                        <>
                          <p
                            data-bs-toggle="modal"
                            className="position-relative m-0"
                            data-bs-target="#staticBackdrop2"
                            style={{ fontSize: "22px" }}
                          >
                            {lang === "ar"
                              ? "تنبيهي في حال التوفر"
                              : "Notify me"}
                          </p>
                          {/* <!-- Modal --> */}
                          <div
                            className="modal fade"
                            id="staticBackdrop2"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="staticBackdropLabel2"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header notify-me d-flex justify-content-between">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div
                                  className="d-flex align-items-center justify-content-between p-3 gray"
                                  style={{ border: "1px solid #dee2e6" }}
                                >
                                  <div className="col-12 d-flex flex-column align-items-start">
                                    <label className="mb-1" htmlFor="email">
                                      {lang === "en"
                                        ? "Email :"
                                        : "البريد الإلكتروني :"}
                                    </label>
                                    <input
                                      id="email"
                                      type="email"
                                      className="form-control"
                                      value={formNotifyMe}
                                      onChange={(e) =>
                                        setFormNotifyMe(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="modal-footer mx-0">
                                  <Button onClick={handleNotifyMe}>
                                    {lang === "en" ? "Save" : "حفظ"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {alertMoreQuantity && (
                      <p className="col-12 text-danger">
                        {lang === "en"
                          ? `* Only available quantity ${checkQuantity}`
                          : `* الكمية المتوفرة فقط ${checkQuantity}`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="mb-5">
        <Container>
          <h3 className={`${typography} mb-5`} dir={lang === "ar" && "rtl"}>
            {lang === "ar" ? "منتجات مقترحة لك" : "Suggested Products"}
          </h3>
          <ReactElasticCarousel
            pagination={false}
            breakPoints={breakPoints}
            renderArrow={myArrow}
            itemsToScroll={1}
          >
            {isLoading ? (
              <>
                <Skeleton height={"300px"} width={"200px"} />
                <Skeleton height={"300px"} width={"200px"} />
                <Skeleton height={"300px"} width={"200px"} />
                <Skeleton height={"300px"} width={"200px"} />
              </>
            ) : (
              productsShow
            )}
          </ReactElasticCarousel>
        </Container>
      </div>
    </>
  );
}
