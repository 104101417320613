import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Lang } from "../../../Context/LangContext";
import { Cart } from "../../../Context/CartContext";

export default function ProductItme(props) {
  const [number, setNumber] = useState(props.getProducts[props.index].quantity);
  let products = JSON.parse(localStorage.getItem("products")) || [];
  const { getData, setGetData, cart } = useContext(Cart);
  // Handle SubTotal
  useEffect(() => {
    const newQuantity = products.map((item, i) => {
      if (props.index === i) {
        return { ...item, quantity: number };
      }
      return item;
    });
    localStorage.setItem("products", JSON.stringify(newQuantity));
    setNumber(props.getProducts[props.index].quantity);
    props.handleSubTotal();
  }, [number, cart]);

  const handleIncreaseClick = () => {
    const updatedProducts = [...props.getProducts];
    let quantity = JSON.parse(localStorage.getItem("quantity")) || [];
    quantity[props.index] += 1;
    updatedProducts[props.index].quantity += 1;
    props.setGetProducts(updatedProducts);
    props.handleSubTotal();
    setNumber((prev) => prev + 1);
    setGetData((prev) => prev + 1);
    localStorage.setItem("quantity", JSON.stringify(quantity));
  };
  const handleDecreaseClick = () => {
    if (number > 1) {
      const updatedProducts = [...products];
      updatedProducts[props.index].quantity -= 1;
      let quantity = JSON.parse(localStorage.getItem("quantity")) || [];
      quantity[props.index] -= 1;
      props.setGetProducts(updatedProducts);
      props.handleSubTotal();
      setNumber((prev) => prev - 1);
      setGetData((prev) => prev - 1);
      localStorage.setItem("quantity", JSON.stringify(quantity));
    }
  };

  // Lang
  const language = useContext(Lang);

  return (
    <div dir={language.lang === "en" ? "ltr" : "rtl"}>
      <div className="d-flex align-items-center gap-3 gray p-3">
        <img src={props.img} alt="product" width={"100px"} />
        <div className="d-flex flex-column gap-3 flex-fill">
          <div className="d-flex align-items-center justify-content-between">
            <p className="text-capitalize mb-0">
              {language.lang === "en" ? props.title : props.title_ar}
            </p>
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{ color: "red" }}
              className="pointer"
              onClick={() => {
                props.handleDeleteItem(props.index);
              }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-md-start justify-content-center col-md-4 col-lg-3 col-12 ">
              <p
                onClick={handleDecreaseClick}
                className={`m-0 border-top ${
                  language.lang === "en" ? "border-start" : "border-end"
                } border-bottom text-center operations`}
              >
                -
              </p>
              <p className="m-0 border text-center operations">{number}</p>
              <p
                onClick={handleIncreaseClick}
                className={`m-0 border-top ${
                  language.lang === "en" ? "border-end" : "border-start"
                } border-bottom text-center operations`}
              >
                +
              </p>
            </div>
            <span>{props.price * number}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
