import {
  faCircleXmark,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function InputImage(props) {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column rounded gap-2 mb-2 p-3"
      style={{
        border: !props.getData ? "2px dashed gray" : "2px dashed #0086fe",
        cursor: !props.getData ? "no-drop" : "pointer",
        position: "relative",
      }}
      onClick={props.handleOpenImage}
    >
      <FontAwesomeIcon
        icon={faCloudArrowUp}
        fontSize={"100px"}
        color={!props.getData ? "gray" : "#0086fe"}
      />
      <p
        className="fw-bold mb-0"
        style={{ color: !props.getData ? "gray" : "#0086fe" }}
      >
        {props.label}
      </p>
    </div>
  );
}
