import { Carousel } from "react-bootstrap";
import Products from "../../../Components/Web/Product/Products";
import { useQuery } from "react-query";
import { Axios } from "../../../Api/axios";
import { BANNER, COMING } from "../../../Api/api";
import "./Home.css";
import { useContext } from "react";
import { Lang } from "../../../Context/LangContext";
import { WindowSize } from "../../../Context/WindowContext";
import ProductsComingSoon from "../../../Components/Web/Product/ProductsComingSoon";

export default function Home() {
  const { lang } = useContext(Lang);
  const { windowSize } = useContext(WindowSize);
  const typography = lang === "ar" ? "f-cairo" : "f-pop";
  const { isLoading, data: banner } = useQuery({
    queryFn: () => Axios.get(`${BANNER}`),
    queryKey: ["homeBanner"],
  });
  const { data: comingSoon } = useQuery({
    queryFn: () => Axios.get(`${COMING}`),
    queryKey: ["homeComingSoon"],
  });

  const showSlider = banner?.data?.map((img, key) => (
    <Carousel.Item className="position-relative" key={key}>
      <img className="bannar" src={img?.img} width={"100%"} alt="bannar" />
      <p
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={`text-white translate-middle position-absolute ${typography}`}
        style={{
          top: windowSize > 767 ? "83%" : "70%",
          zIndex: 1,
          fontWeight: "bold",
          left: lang === "en" ? "35%" : "65%",
          width: "50%",
          fontSize: windowSize > 768 && "40px",
          textShadow: "4px 4px 5px rgba(0, 0, 0, 0.5)",
        }}
      >
        {lang === "en" ? img?.title_en : img?.title_ar}
      </p>
    </Carousel.Item>
  ));
  return (
    <div style={{ marginTop: "70px", marginBottom: "70px" }}>
      <Carousel className="bg-custom-grey bannar">{showSlider}</Carousel>
      <div className="pb-5 mt-5">
        <Products />
      </div>
      {comingSoon?.data?.length !== 0 && (
        <div className="pb-5 mt-5">
          <ProductsComingSoon />
        </div>
      )}
    </div>
  );
}
