import { Link, Outlet } from "react-router-dom";
import NavBar from "../../Components/Web/Navbar/Navbar";
import Footer from "../../Components/Web/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Lang } from "../../Context/LangContext";
import { useContext } from "react";

export default function Website() {
  const { lang } = useContext(Lang);
  const phoneNumber = "+97470202272";

  // const handleClick = () => {
  //   window.location.href = `https://wa.me/${phoneNumber}`;
  // };

  return (
    <div>
      <div
        className="link-whtasapp position-fixed d-flex align-items-end justify-content-center flex-column"
        style={{
          zIndex: 5,
          bottom: "10px",
          right: "10px",
        }}
      >
        <a
          className="position-relative  d-flex align-items-center justify-content-center"
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          style={{
            backgroundColor: "#00BE21",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        >
          <FontAwesomeIcon
            style={{ fontSize: "36px", color: "white", zIndex: 5 }}
            icon={faWhatsapp}
          />
          <p
            className="contact-whatsapp"
            style={{
              zIndex: 1,
              fontSize: lang === "ar" && "22px",
              padding: lang === "ar" && "2px 18px",
            }}
          >
            {lang === "en"
              ? "Chat with us on Whatsapp"
              : "تواصل معنا عبر الواتساب"}
          </p>
        </a>
      </div>
      <NavBar />
      <div style={{ marginTop: "70px" }}>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}
