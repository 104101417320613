import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Lang } from "../../../Context/LangContext";
import { Axios } from "../../../Api/axios";
import { ABOUT } from "../../../Api/api";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

export default function AboutUs() {
  const { lang } = useContext(Lang);

  const { isLoading, data } = useQuery({
    queryFn: () => Axios.get(`${ABOUT}`),
    queryKey: ["about-us"],
    staleTime: Infinity,
  });

  return (
    <Container>
      <div
        style={{ paddingTop: "70px", paddingBottom: "calc(70px + 2rem)" }}
        dir={lang === "ar" && "rtl"}
        className={`d-flex align-items-center justify-content-between flex-wrap ${
          lang === "ar" && "f-cairo"
        }`}
      >
        <div className="col-lg-6 col-md-4 col-12 ">
          {isLoading ? (
            <>
              <Skeleton height={"70px"} />
              <Skeleton height={"500px"} />
            </>
          ) : (
            <>
              <h1>
                {lang === "ar"
                  ? data?.data[0]?.title_ar
                  : data?.data[0]?.title_en}
              </h1>
              <p className="mb-0">
                {lang === "ar"
                  ? data?.data[0]?.description_ar
                  : data?.data[0]?.description_en}{" "}
              </p>
            </>
          )}
        </div>

        <div className="col-md-5 col-12 mt-5">
          {isLoading ? (
            <Skeleton height={"300px"} />
          ) : (
            <img src={data?.data[0]?.image} width={"100%"} alt="about-sajda" />
          )}
        </div>
      </div>
    </Container>
  );
}
