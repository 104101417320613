import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import { Axios } from "../../../Api/axios";
import { useNavigate } from "react-router-dom";
import { COUPON } from "../../../Api/api";
import { Lang } from "../../../Context/LangContext";

export default function AddCoupon() {
  const [form, setForm] = useState({
    name_en: "",
    name_ar: "",
    coupon: "",
    discount: "",
    expires_at: "",
  });
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState("");
  const [loading, setLoading] = useState("");
  const nav = useNavigate("");

  const { lang } = useContext(Lang);

  const typography = lang === "ar" ? "f-cairo" : "f-pop";
  useEffect(() => {
    // الحصول على تاريخ اليوم
    const today = new Date().toISOString().split("T")[0];
    // تعيين قيمة اليوم كقيمة الحد الأدنى لحقل التاريخ
    setMinDate(today);
  }, []);
  // Handle Hange Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
    setError();
  }
  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await Axios.post(`${COUPON}`, form);
      setLoading(false);
      setForm({
        name_en: "",
        name_ar: "",
        coupon: "",
        discount: "",
        expires_at: "",
      });
      nav("/dashboard/coupons");
    } catch (err) {
      setLoading(false);
      setError("هذا الكوبون موجود مسبقا بالفعل");
    }
  }
  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  // Handle scroll in input
  const inputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };
    const inputElement = inputRef.current;
    inputElement.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      inputElement.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div className={`w-100 ${typography}`}>
      <div className="p-4">
        <h2 className="line">صفحة إنشاء كوبون </h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName_ar">
            <Form.Label>اسم الكوبون باللغة العربية :</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.name_ar}
              name={"name_ar"}
              type="text"
              placeholder={"أدخل اسم الكوبون باللغة العربية..."}
              ref={focus}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicName_en">
            <Form.Label> اسم الكوبون باللغة الإنكليزية :</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.name_en}
              name={"name_en"}
              type="text"
              placeholder={"أدخل اسم الكوبون باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicCoupon"
          >
            <Form.Label>رمز الكوبون : </Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.coupon}
              name={"coupon"}
              type="text"
              placeholder={"أدخل رمز الكوبون..."}
            />
            {error !== "" && (
              <p className="position-absolute top-100 end-0 text-danger">
                {error}
              </p>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDiscount">
            <Form.Label>نسبة الحسم : </Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.discount}
              name={"discount"}
              type="number"
              ref={inputRef}
              placeholder={"أدخل نسبة الحسم..."}
            />
          </Form.Group>
          <Form.Group className="mb-3 col-3" controlId="duedate">
            <Form.Label>تاريخ الإنتهاء : </Form.Label>
            <Form.Control
              type="date"
              placeholder="Due date"
              min={minDate}
              onChange={handleChange}
              name="expires_at"
              value={form.expires_at}
            />
          </Form.Group>
          {loading ? (
            <div
              style={{ width: "86px", height: "37px" }}
              className="bg-main-color py-3 rounded d-flex align-items-center justify-contnet-center"
            >
              <LoadingBtn />
            </div>
          ) : (
            <Button type="submit">إضافة</Button>
          )}
        </Form>
      </div>
    </div>
  );
}
