import { consts } from "@itseasy21/react-elastic-carousel";
import "./SliderArrow.css";

export default function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? (
      <svg
        className="image-gallery-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="6 0 12 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
    ) : (
      <svg
        className="image-gallery-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="6 0 12 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    );
  return (
    <p className="arrow" onClick={onClick} disabled={isEdge}>
      {pointer}
    </p>
  );
}
