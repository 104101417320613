import {
  faCopy,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import AreYouSure from "../AreYouSure/AreYouSure";
import LoadingBtn from "../../Loading/LoadingBtn/LoadingBtn";

export default function TableShow(props) {
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [itemId, setItemId] = useState();
  const searchShow = props.search !== undefined ? props.search : true;
  const dateShow = props.date !== undefined ? props.date : true;

  useEffect(() => {
    props.isSuccess && setShowPopUp(false);
  }, [props.isSuccess]);

  const dataFilter = props.data?.filter(
    (i) =>
      (i[props.searchItem] != null &&
        i[props.searchItem]?.toLowerCase().includes(search.toLowerCase())) ||
      i[props.searchItem] === null
  );
  const data = dataFilter?.filter((item) => item.created_at.includes(date));

  const headerShow = props.header.map((item, key) => (
    <th key={key}>{item.name}</th>
  ));
  const dataShow = data?.map((item, key) => (
    <tr key={key}>
      <td>{key + 1}</td>
      {props.header.map((item2, key2) => (
        <td
          style={{
            maxWidth: "0",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          key={key2}
        >
          {item2.key === "created_at" || item2.key === "updated_at" ? (
            new Date(item[item2.key]).toLocaleDateString()
          ) : item2.key === "img" ? (
            <img width={"50px"} src={item[item2.key]} alt="img-banner" />
          ) : item2.key === "discount" ? (
            `%${item[item2.key]}`
          ) : (
            item[item2.key]
          )}
        </td>
      ))}
      {props.action === true ? (
        <td>
          <div className="d-flex align-items-center gap-2">
            {props.actionEdit && (
              <Link to={`${item.id}`}>
                <FontAwesomeIcon fontSize={"19px"} icon={faPenToSquare} />
              </Link>
            )}
            <FontAwesomeIcon
              onClick={() => {
                setShowPopUp(true);
                setItemId(item.id);
              }}
              cursor={"pointer"}
              fontSize={"19px"}
              color="red"
              icon={faTrash}
            />

            {props.showDublicate ? (
              props.duplicateLoading ? (
                <LoadingBtn />
              ) : (
                <FontAwesomeIcon
                  onClick={() => props.handleDuplicate(item.id)}
                  cursor={"pointer"}
                  fontSize={"19px"}
                  icon={faCopy}
                />
              )
            ) : (
              ""
            )}
          </div>
        </td>
      ) : (
        ""
      )}
    </tr>
  ));

  return (
    <>
      <AreYouSure
        checkout={false}
        title={
          props.showDublicate
            ? "هذا الإجراء سيحذف المنتج ولا يمكن التراجع عنه"
            : props.coupon
            ? "هذا الإجراء سيحذف الكوبون ولا يمكن التراجع عنه"
            : "هذا الإجراء سيحذف البنر ولا يمكن التراجع عنه"
        }
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
        handleOperation={() => {
          props.delete(itemId);
        }}
        loading={props.deleteLoading}
      />

      <div className="d-flex flex-wrap justify-content-between gap-3">
        {dateShow && (
          <Form.Group
            className="mb-3 col-12 col-sm-4 "
            controlId="formBasicCategories"
          >
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
        )}
        {searchShow && (
          <Form.Group
            className="mb-3 col-12 col-sm-4 "
            controlId="formBasicCategories"
          >
            <Form.Control
              type="search"
              value={search}
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form.Group>
        )}
      </div>
      <div style={{ overflow: "auto" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>id</th>
              {headerShow}
              {props.action === true ? <th>الإدارة </th> : ""}
            </tr>
          </thead>
          <tbody>{dataShow}</tbody>
        </Table>
      </div>
    </>
  );
}
