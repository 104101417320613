import { createContext, useState } from "react";

export const Cart = createContext();

export default function CartContext({ children }) {
  const [cart, setCart] = useState(0);
  const [total, setTotal] = useState("");
  const [getData, setGetData] = useState(0);

  return (
    <Cart.Provider
      value={{ cart, setCart, total, setTotal, getData, setGetData }}
    >
      {children}
    </Cart.Provider>
  );
}
