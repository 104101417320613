import { Accordion, Form } from "react-bootstrap";
import InputImage from "../Inputs/InputImage";
import { useEffect, useRef } from "react";
import { useState } from "react";
import "./accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCopy,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

export default function CustomAccordion(props) {
  const [accordions, setAccordions] = useState([
    {
      name_ar: "",
      name_en: "",
      color: "#000",
      images: [],
      image: "",
      quantity: "",
    },
  ]);

  useEffect(() => {
    if (props.getRealData) {
      const accordionsComingFromServer = props.getRealData;
      const addToAccordions = accordionsComingFromServer.map((acc) => {
        return {
          name_ar: acc.name_ar,
          name_en: acc.name_en,
          color: acc.color,
          images: acc.product_image,
          image: acc.url,
          quantity: acc.quantity,
        };
      });

      setAccordions(addToAccordions);
    }
  }, [props.getRealData]);

  const inputRefs = useRef([]);
  useEffect(() => {
    props.getData(accordions);

    // Handle scroll in input
    const handleWheel = (e) => {
      e.preventDefault();
    };
    const inputElements = inputRefs.current;
    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
    });
    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleWheel);
      });
    };
  }, [accordions]);
  const registerInputRef = (ref) => {
    if (ref && !inputRefs.current.includes(ref)) {
      inputRefs.current.push(ref);
    }
  };

  // Handle Changes

  const handleFormChange = (index, event) => {
    let data = [...accordions];
    data[index][event.target.name] = event.target.value;
    setAccordions(data);
  };

  const handleChangeImages = (index, e) => {
    let imgs = [...accordions];
    const newImages = Array.from(e.target.files);
    imgs[index].images = [...imgs[index].images, ...newImages];
    setAccordions(imgs);
  };

  const handleSingleImage = (e, index) => {
    let imgs = [...accordions];
    if (e.target.files.length > 0) {
      imgs[index].image = e.target.files[0];
    }
    setAccordions(imgs);
  };

  // Handle add Inputs

  const handleAddAccordion = () => {
    let newfield = {
      name_ar: "",
      name_en: "",
      color: "#000",
      images: [],
      image: "",
      quantity: "",
    };
    setAccordions([...accordions, newfield]);
  };
  // handle add copy accordion
  const handleAddCopyAccordion = (e, index, id) => {
    let _accordions = [...accordions];
    let newfield = {
      name_ar: _accordions[index].name_ar,
      name_en: _accordions[index].name_en,
      color: _accordions[index].color,
      images: _accordions[index].images,
      image: _accordions[index].image,
      quantity: _accordions[index].quantity,
    };
    setAccordions([...accordions, newfield]);
  };

  //   Handle Delete
  const handleDeleteAccordion = (e, index) => {
    setAccordions((prev) => prev.filter((j, key) => key !== index && j));
  };

  const handleDeleteImage = (e, key, index) => {
    let updatedAccordions = [...accordions];
    let accordion = updatedAccordions[key];
    let updatedImages = [...accordion.images];
    updatedImages.splice(index, 1);
    accordion.images = updatedImages;
    updatedAccordions[key] = accordion;
    setAccordions(updatedAccordions);
  };

  // Handle Focus on input file
  const openImage = useRef([]);
  const openImage2 = useRef([]);

  const handleOpenImage = (index) => {
    openImage.current[index].click();
  };
  const handleOpenImage2 = (index) => {
    openImage2.current[index].click();
  };

  // Save Reference for dragItem & dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = (key) => {
    // duplicate items
    let updatedAccordions = [...accordions];
    let accordion = updatedAccordions[key];
    let _images = [...accordion.images];
    // remove and save dragged  item content
    const draggedItemContent = _images.splice(dragItem.current, 1)[0];
    // switch the position
    _images.splice(dragOverItem.current, 0, draggedItemContent);
    // Update accordion with new images
    accordion = {
      ...accordion,
      images: _images,
    };
    // reset the positon ref
    dragItem.current = null;
    dragOverItem.current = null;

    updatedAccordions[key] = accordion;

    // update The actual array
    setAccordions(updatedAccordions);
  };

  // Images Show
  const imagesShow = accordions.map((img, key) =>
    img.images.map((i, index) => (
      <div
        draggable
        onDragStart={(e) => (dragItem.current = index)}
        onDragEnter={(e) => (dragOverItem.current = index)}
        onDragEnd={() => handleSort(key)}
        onDragOver={(e) => e.preventDefault()}
        key={index}
        className="p-2 border w-100 position-relative"
      >
        <div className="d-flex align-items-center justify-content-start gap-2 rounded">
          <FontAwesomeIcon
            className="position-absolute top-0 end-0 z-3 pointer fs-6"
            icon={faCircleXmark}
            color="red"
            style={{ transform: "translate(50%,-50%)" }}
            onClick={(e) => handleDeleteImage(e, key, index)}
          />
          <img
            src={!i.url ? URL.createObjectURL(i) : i.url}
            width={"80px"}
            alt="img-title"
          ></img>
          {!i.url ? (
            <div className="ms-3">
              <p className="mb-1">{i.name}</p>
              <p className="mb-1">
                {i.size / 1024 < 900
                  ? (i.size / 1024).toFixed(2) + "KB"
                  : (i.size / (1024 * 1024)).toFixed(2) + "MB"}
              </p>
            </div>
          ) : (
            <p className="m-0">صورة تم رفعها سابقا</p>
          )}
        </div>
        <div className="custom-progress">
          <span percent={"50%"} className="inner-progress"></span>
        </div>
      </div>
    ))
  );

  return (
    <>
      {accordions.map((item, index) => (
        <Accordion key={index} className="mt-3 position-relative">
          {index !== 0 && (
            <>
              <FontAwesomeIcon
                className="position-absolute top-0 end-0 z-4 pointer fs-5"
                icon={faCircleXmark}
                color="red"
                style={{ transform: "translate(50%,-50%)" }}
                onClick={(e) => handleDeleteAccordion(e, index)}
              />
              <FontAwesomeIcon
                className="position-absolute z-4 pointer fs-5"
                style={{
                  top: "18px",
                  right: "-0.5rem",
                }}
                onClick={(e) => handleAddCopyAccordion(e, index, item.id)}
                cursor={"pointer"}
                fontSize={"19px"}
                icon={faCopy}
              />
            </>
          )}
          <Accordion.Item eventKey="0">
            <Accordion.Header dir="rtl">
              إضافة صورة ولون للمنتج{" "}
            </Accordion.Header>
            <Accordion.Body>
              <>
                <Form.Group className="mb-3" controlId="formBasicColorAr">
                  <Form.Label>لون المنتج باللغة العربية :</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="اكتب اسم لون المنتج باللغة العربية"
                    name="name_ar"
                    value={item.name_ar}
                    onChange={(e) => handleFormChange(index, e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicColor">
                  <Form.Label>لون المنتج باللغة الأنكليزية :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name_en"
                    value={item.name_en}
                    placeholder="اكتب اسم لون المنتج باللغة الأنكليزية"
                    onChange={(e) => handleFormChange(index, e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3 d-flex align-items-center gap-4">
                  <label htmlFor="hex-color">
                    تحديد اللون التقريبي للمنتج :
                  </label>
                  <input
                    type="color"
                    name="color"
                    id="hex-color"
                    value={item.color}
                    onChange={(e) => handleFormChange(index, e)}
                    className="pointer"
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicQuantity">
                  <Form.Label>الكمية :</Form.Label>
                  <Form.Control
                    onChange={(e) => handleFormChange(index, e)}
                    value={item.quantity}
                    name={"quantity"}
                    type="number"
                    ref={registerInputRef}
                    placeholder={"أدخل كمية المنتج..."}
                  />
                </Form.Group>
                <Form.Group className="mb-0" controlId="formBasicImage">
                  <Form.Label>صورة المنتج :</Form.Label>
                  <Form.Control
                    ref={(e) => (openImage.current[index] = e)}
                    onChange={(e) => handleSingleImage(e, index)}
                    hidden
                    type="file"
                  />
                </Form.Group>
                <InputImage
                  label="Upload Image"
                  getData
                  handleOpenImage={() => handleOpenImage(index)}
                />
                {accordions[index].image && (
                  <div className="d-flex flex-column align-items-start justify-content-start gap-2 my-3  position-relative">
                    <img
                      src={
                        typeof accordions[index].image === "object"
                          ? URL.createObjectURL(accordions[index].image)
                          : accordions[index].image
                      }
                      alt="icon-img"
                      width={"20%"}
                    />
                  </div>
                )}
                <Form.Group className="mb-0" controlId="formBasicImages">
                  <Form.Label>صور المنتج :</Form.Label>
                  <Form.Control
                    ref={(e) => (openImage2.current[index] = e)}
                    onChange={(e) => {
                      handleChangeImages(index, e);
                      e.target.value = null;
                    }}
                    hidden
                    type="file"
                    multiple
                  />
                </Form.Group>
                <InputImage
                  label="Upload Images"
                  getData
                  handleOpenImage={() => handleOpenImage2(index)}
                />
                {/* show images */}
                <div className="d-flex align-items-center flex-wrap justify-content-start gap-2 my-3">
                  {imagesShow[index]}
                </div>
              </>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}

      <div
        className="col-12 mt-3 p-3 border rounded d-flex align-items-center justify-content-between pointer"
        onClick={handleAddAccordion}
      >
        <h5 className="m-0">إضافة لون جديد</h5>
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </>
  );
}
