import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import Button from "react-bootstrap/Button";
import { Menu } from "../../../Context/MenuContext";
import Cookie from "cookie-universal";
import { Axios } from "../../../Api/axios";
import { LOGOUT } from "../../../Api/api";
import { useNavigate } from "react-router-dom";

export default function TopBarDash() {
  const menu = useContext(Menu);
  const setIsOpen = menu.setIsOpen;
  const cookie = Cookie();
  const nav = useNavigate("");
  // handle Logout
  async function handleLogout() {
    try {
      const res = Axios.get(`${LOGOUT}`);
      cookie.remove("Bearer");
      nav("/login");
    } catch (err) {}
  }

  return (
    <div className="top-bar z-4">
      <div className="d-flex justify-content-between align-items-center h-100">
        <div className="d-flex gap-3 align-items-center flex-row-reverse">
          <h3 className="mb-0">لوحة التحكم</h3>
          <FontAwesomeIcon
            onClick={() => setIsOpen((prev) => !prev)}
            cursor={"pointer"}
            icon={faBars}
          />
        </div>
        <Button variant="primary" onClick={handleLogout}>
          تسجيل الخروج
        </Button>
      </div>
    </div>
  );
}
