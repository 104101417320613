import { Outlet } from "react-router-dom";
import "./dashboard.css";
import { useContext } from "react";
import { WindowSize } from "../../Context/WindowContext";
import { Menu } from "../../Context/MenuContext";
import SideBarDash from "../../Components/Dashboard/Bars/SideBarDash";
import TopBarDash from "../../Components/Dashboard/Bars/TopBarDash";

export default function Dashboard() {
  // useContext
  const windowContext = useContext(WindowSize);
  const windowSize = windowContext.windowSize;
  const menu = useContext(Menu);
  const isOpen = menu.isOpen;
  return (
    <div className="dashboard f-cairo position-relative">
      <TopBarDash />
      <div
        className="d-flex gap-1"
        style={{
          marginTop: "70px",
        }}
      >
        <div
          style={{
            marginLeft: windowSize > 768 ? (isOpen ? "220px" : "65px") : 0,
          }}
        >
          <SideBarDash />
        </div>
        <Outlet />
      </div>
    </div>
  );
}
