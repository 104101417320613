import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./product.css";
import { useContext } from "react";
import { Lang } from "../../../Context/LangContext";

export default function Product(props) {
  const { lang } = useContext(Lang);

  const typography = lang === "ar" ? "f-cairo" : "f-pop";

  return (
    <div
      className={`${props.className} text-${lang === "ar" ? "end" : "start"}`}
    >
      <Link
        to={`/product/${props.id}`}
        className="h-100 m-1 bg-custom-grey position-relative d-block text-decoration-none product-card"
      >
        <div
          className={`position-absolute top-0 ${
            lang === "en" ? "end" : "start"
          }-0 ${
            lang === "en" ? "move-icon" : "move-icon-ar"
          } opacity-0 bg-white text-black p-2`}
        >
          <FontAwesomeIcon
            fontSize="16px"
            color="black"
            icon={faCartShopping}
          />
        </div>
        {props.quantity !== 0 ? (
          props.discount !== "null" &&
          Number(props.discount) !== 0 && (
            <div
              className={`position-absolute top-0 ${
                lang === "en" ? "start" : "end"
              }-0 ${typography} p-3 bg-secondary`}
            >
              <p className="m-0 text-white">
                {lang === "en" ? "Sale!" : "حسم!"}
              </p>
            </div>
          )
        ) : (
          <div
            className={`position-absolute top-0 ${
              lang === "en" ? "start" : "end"
            }-0 ${typography} p-3 bg-secondary`}
          >
            <p className="m-0 text-white">
              {lang === "en" ? "out of stock" : "غير متوفر حاليا"}
            </p>
          </div>
        )}
        <img
          src={props.img}
          alt="img"
          className="img-fluid"
          style={{ height: "200px", objectFit: "cover", width: "100%" }}
        />
        <div className=" pt-5 text-black px-4">
          <h6 className={`${typography} text-truncate`}>
            {lang === "en" ? props.title : props.title_ar}
          </h6>
          <h6
            dir={lang === "ar" && "rtl"}
            className={`${typography} text-secondary text-truncate`}
          >
            {lang === "en" ? props.description : props.description_ar}
          </h6>

          {props.discount !== "null" && Number(props.discount) !== 0 ? (
            <>
              <span className="text-decoration-line-through text-secondary">
                {props.price}
              </span>
              <p className="fw-bold price-color d-inline ms-2">
                {" "}
                {props.discount}
              </p>{" "}
            </>
          ) : (
            <p className={`m-0 ${typography}`} dir={lang === "ar" && "rtl"}>
              {props.price + " " + (lang === "ar" ? "ر.ق" : "QAR")}
            </p>
          )}
        </div>
        <div
          className="d-flex align-items-center flex-wrap gap-3 px-4 pt-3 pb-3"
          dir={lang === "ar" && "rtl"}
        >
          {props?.colors?.map((color, key) => (
            <div
              key={key}
              className="rounded-pill"
              style={{
                height: "20px",
                width: "40px",
                backgroundColor: color.color,
                cursor: "pointer",
                border: "2px solid white",
                outline: `2px solid ${color.color}`,
              }}
            ></div>
          ))}
        </div>
      </Link>
    </div>
  );
}
