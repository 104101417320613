import { SubscribersAPI } from "../../../Api/api";
import { Axios } from "../../../Api/axios";
import { useQuery } from "react-query";
import TableShow from "../../../Components/Dashboard/Table/Table";

export default function Subscribers() {
  const { data } = useQuery({
    queryFn: () => Axios.get(`${SubscribersAPI}`),
    queryKey: ["subscribersAPI"],
  });

  const header = [
    {
      key: "full_name",
      name: " الأسم الكامل الخاص بالمشترك",
    },
    {
      key: "email",
      name: "البريد الإلكتروني الخاص بالمشترك",
    },
  ];
  return (
    <div className="w-100">
      <div className="p-4">
        <h2 className="line">تحليلات المشتركين</h2>
      </div>

      <div className="m-3">
        <TableShow
          best
          header={header}
          data={data?.data}
          searchItem="email"
          showDublicate={false}
          action={false}
        />
      </div>
    </div>
  );
}
