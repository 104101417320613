import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Axios } from "../../../Api/axios";
import { useNavigate, useParams } from "react-router-dom";

import { useEffect } from "react";
import CustomAccordion from "../../../Components/Dashboard/Accordion/CustomAccordion";
import { PRO } from "../../../Api/api";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";

export default function UpdateProduct() {
  // State Form
  const [form, setForm] = useState({
    title: "",
    title_ar: "",
    description: "",
    description_ar: "",
    price: "",
    discount: "",
    price_suadia: "",
    discount_suadia: "",
    price_emarat: "",
    discount_emarat: "",
    price_dollar: "",
    discount_dollar: "",
    coming_soon: 1,
  });
  const [childData, setChildData] = useState([{}]);
  const [getRealData, setGetRealData] = useState([]);
  const { id } = useParams();

  // Get Product Information
  useEffect(() => {
    Axios.get(`${PRO}/${id}`).then((res) => {
      setForm({
        title: res.data[0]?.title_en,
        title_ar: res.data[0]?.title_ar,
        description: res.data[0]?.description_en,
        description_ar: res.data[0]?.description_ar,
        price: res.data[0]?.price,
        discount: res.data[0]?.discount,
        price_suadia: res.data[0]?.price_suadia,
        discount_suadia: res.data[0]?.discount_suadia,
        price_emarat: res.data[0]?.price_emarat,
        discount_emarat: res.data[0]?.discount_emarat,
        price_dollar: res.data[0]?.price_dollar,
        discount_dollar: res.data[0]?.discount_dollar,
        coming_soon: res.data[0]?.coming_soon,
      });
      setGetRealData(res.data[0]?.colors);
    });
  }, []);

  // Disable Btn
  const disableBtn = () => {
    let disabled = false;
    for (const item in form) {
      if (
        form[item]?.length === 0 &&
        item !== "discount" &&
        item !== "discount_suadia" &&
        item !== "discount_emarat" &&
        item !== "discount_dollar"
      ) {
        disabled = true;
        break;
      }
    }
    for (let i = 0; i < childData.length; i++) {
      for (const key in childData[i]) {
        if (childData[i][key]?.length === 0 && key !== "images") {
          disabled = true;
          break;
        }
      }
    }
    return disabled;
  };

  // Loading
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  // Handle Hange Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("title_en", form.title);
    formData.append("title_ar", form.title_ar);
    formData.append("description_en", form.description);
    formData.append("description_ar", form.description_ar);
    formData.append("price", form.price);
    formData.append("discount", form.discount);
    formData.append("price_suadia", form.price_suadia);
    formData.append("discount_suadia", form.discount_suadia);
    formData.append("price_emarat", form.price_emarat);
    formData.append("discount_emarat", form.discount_emarat);
    formData.append("price_dollar", form.price_dollar);
    formData.append("discount_dollar", form.discount_dollar);
    formData.append("coming_soon", form.coming_soon);
    for (let i = 0; i < childData.length; i++) {
      formData.append(`colors[${i}][name_ar]`, childData[i].name_ar);
      formData.append(`colors[${i}][name_en]`, childData[i].name_en);
      formData.append(`colors[${i}][color]`, childData[i].color);
      formData.append(`colors[${i}][quantity]`, childData[i].quantity);
      formData.append(`colors[${i}][image]`, childData[0].image);
      for (let j = 0; j < childData[i].images.length; j++) {
        formData.append(
          `colors[${i}][images][]`,
          childData[i].images[j].url
            ? childData[i].images[j].url
            : childData[i].images[j]
        );
      }
    }

    try {
      const res = await Axios.post(`${PRO}/${id}`, formData);
      nav("/dashboard/products");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  // Handle scroll in input
  const inputRefs = useRef([]);
  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };
    const inputElements = inputRefs.current;
    inputElements.forEach((inputElement) => {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
    });
    return () => {
      inputElements.forEach((inputElement) => {
        inputElement.removeEventListener("wheel", handleWheel);
      });
    };
  }, []);
  const registerInputRef = (ref) => {
    if (ref && !inputRefs.current.includes(ref)) {
      inputRefs.current.push(ref);
    }
  };

  return (
    <div className="home-dash w-100">
      <div className="p-4">
        <h2 className="line">تعديل المنتج</h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>عنوان المنتج باللغة العربية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_ar}
              name={"title_ar"}
              type="text"
              placeholder={"أدخل عنوان المنتج باللغة العربية..."}
              ref={focus}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle">
            <Form.Label>عنوان المنتج باللغة الإنكليزية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title}
              name={"title"}
              type="text"
              placeholder={"أدخل عنوان المنتج باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDisc_ar">
            <Form.Label>وصف المنتج باللغة العربية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.description_ar}
              name={"description_ar"}
              type="text"
              placeholder={"أدخل وصف المنتج باللغة العربية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDisc">
            <Form.Label>وصف المنتج باللغة الإنكليزية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.description}
              name={"description"}
              type="text"
              placeholder={"أدخل وصف المنتج باللغة الإنكليزية..."}
            />
          </Form.Group>
          <div className="d-flex align-items-center flex-wrap">
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="formBasicPrice"
            >
              <div className="ms-2">
                <Form.Label>السعر (ريال قطري) :</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={form.price}
                  name={"price"}
                  type="number"
                  ref={registerInputRef}
                  placeholder={"أدخل سعر المنتج..."}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3  col-lg-6 col-12"
              controlId="formBasicDiscount"
            >
              <Form.Label>السعر بعد الخصم (اختياري) :</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.discount}
                name={"discount"}
                type="number"
                ref={registerInputRef}
                placeholder={"أدخل الخصم على هذا المنتج..."}
              />
            </Form.Group>
          </div>{" "}
          <div className="d-flex align-items-center flex-wrap">
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="formBasicPriceSuadia"
            >
              <div className="ms-2">
                <Form.Label>السعر (ريال سعودي) :</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={form.price_suadia}
                  name={"price_suadia"}
                  type="number"
                  ref={registerInputRef}
                  placeholder={"أدخل سعر المنتج..."}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3  col-lg-6 col-12"
              controlId="formBasicDiscount"
            >
              <Form.Label>السعر بعد الخصم (اختياري) :</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.discount_suadia}
                name={"discount_suadia"}
                type="number"
                ref={registerInputRef}
                placeholder={"أدخل الخصم على هذا المنتج..."}
              />
            </Form.Group>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="formBasicPriceSuadia"
            >
              <div className="ms-2">
                <Form.Label>السعر (درهم إماراتي) :</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={form.price_emarat}
                  name={"price_emarat"}
                  type="number"
                  ref={registerInputRef}
                  placeholder={"أدخل سعر المنتج..."}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3  col-lg-6 col-12"
              controlId="formBasicDiscount"
            >
              <Form.Label>السعر بعد الخصم (اختياري) :</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.discount_emarat}
                name={"discount_emarat"}
                type="number"
                ref={registerInputRef}
                placeholder={"أدخل الخصم على هذا المنتج..."}
              />
            </Form.Group>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <Form.Group
              className="mb-3 col-lg-6 col-12"
              controlId="formBasicPriceSuadia"
            >
              <div className="ms-2">
                <Form.Label>السعر (دولار) :</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={form.price_dollar}
                  name={"price_dollar"}
                  type="number"
                  ref={registerInputRef}
                  placeholder={"أدخل سعر المنتج..."}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3  col-lg-6 col-12"
              controlId="formBasicDiscount"
            >
              <Form.Label>السعر بعد الخصم (اختياري) :</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.discount_dollar}
                name={"discount_dollar"}
                type="number"
                ref={registerInputRef}
                placeholder={"أدخل الخصم على هذا المنتج..."}
              />
            </Form.Group>
          </div>
          <CustomAccordion getRealData={getRealData} getData={setChildData} />
          {loading ? (
            <div
              style={{ width: "86px", height: "37px" }}
              className="bg-main-color mt-3 py-3 rounded d-flex align-items-center justify-contnet-center"
            >
              <LoadingBtn />
            </div>
          ) : (
            <Button
              disabled={disableBtn()}
              className="mt-3"
              variant="primary"
              type="submit"
            >
              {"تعديل المنتج "}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}
