import Button from "react-bootstrap/Button";
import { Axios } from "../../../Api/axios";
import { COMING } from "../../../Api/api";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TableShow from "../../../Components/Dashboard/Table/Table";

export default function ProductsComingSoon() {
  const header = [
    {
      key: "title_en",
      name: "عنوان المنتج En",
    },
    {
      key: "title_ar",
      name: "عنوان المنتج Ar",
    },
    {
      key: "description_en",
      name: "وصف المنتج",
    },
    {
      key: "created_at",
      name: "أنشأت بتاريخ",
    },
    {
      key: "updated_at",
      name: "عدلت بتاريخ",
    },
  ];

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryFn: () => Axios.get(`${COMING}`),
    queryKey: ["dashProductsComingSoon"],
  });

  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: async (id) => await Axios.delete(`${COMING}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashProductsComingSoon"]);
    },
  });

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center p-3">
        <h2 className="line"> المنتجات التي ستتوفر قريبا</h2>
        <Link to={"/dashboard/products-coming-soon/add"}>
          <Button variant="primary">انشاء منتج جديد</Button>
        </Link>
      </div>
      <div className="m-3">
        <TableShow
          best
          showDublicateaction
          action
          actionEdit
          header={header}
          data={data?.data}
          delete={mutateAsync}
          searchItem="title_en"
          deleteLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
}
