import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
export default function RequireTransactionBack() {
  const nav = useNavigate();
  const allowedReferrer = "https://skipcashtest.azurewebsites.net/pay/cs";

  useEffect(() => {
    const referrer = document.referrer;
    if (referrer !== allowedReferrer) {
      nav("/");
    }
  }, []);
  return <Outlet />;
}
