import { Link } from "react-router-dom";
import LoadingBtn from "../../Loading/LoadingBtn/LoadingBtn";
import "./AreYouSure.css";
import { useContext } from "react";
import { Lang } from "../../../Context/LangContext";
import { Button } from "react-bootstrap";
export default function AreYouSure({
  title,
  showPopUp,
  setShowPopUp,
  handleOperation,
  loading,
  checkout,
}) {
  const { lang } = useContext(Lang);
  const typography = lang === "ar" ? "f-cairo" : "f-pop";
  return (
    <>
      {showPopUp && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: "9999" }}
        ></div>
      )}
      <div
        className={`position-fixed top-50 start-50 p-5 bg-white text-center col-lg-7 rounded shadow col-md-9 col-11 ${typography} scale-popup ${
          showPopUp && "scale-popup-scaling"
        }`}
        style={{ zIndex: "99999" }}
      >
        {!checkout && <h1>هل أنت متأكد؟</h1>}
        <h3>{title}</h3>
        {checkout && (
          <p>
            {lang === "en"
              ? "You will be directed to the home page"
              : "سيتم توجيهك الى الصفحة الرئيسية"}{" "}
          </p>
        )}
        {!checkout ? (
          <div className="d-flex align-items-stretch justify-content-center gap-4 mt-4">
            <div
              onClick={handleOperation}
              className="m-0 py-2 col-4 bg-primary text-white rounded pointer d-flex align-items-center justify-content-cetner"
            >
              {loading ? (
                <LoadingBtn classess={"m-auto"} />
              ) : (
                <h1 className="m-auto"> نعم </h1>
              )}
            </div>
            <h1
              onClick={() => setShowPopUp(false)}
              className="m-0 py-2 col-4 bg-secondary text-white rounded pointer"
            >
              لا
            </h1>
          </div>
        ) : (
          <Link to={"/"}>
            <Button>{lang === "en" ? "Okay" : "حسنا"}</Button>
          </Link>
        )}
      </div>
    </>
  );
}
