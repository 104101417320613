import Button from "react-bootstrap/Button";

import { Axios } from "../../../Api/axios";
import { PRO } from "../../../Api/api";
import { Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TableShow from "../../../Components/Dashboard/Table/Table";
import { useState } from "react";

export default function Products() {
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const header = [
    {
      key: "title_en",
      name: "عنوان المنتج En",
    },
    {
      key: "title_ar",
      name: "عنوان المنتج Ar",
    },
    {
      key: "description_en",
      name: "وصف المنتج",
    },
    {
      key: "created_at",
      name: "أنشأت بتاريخ",
    },
    {
      key: "updated_at",
      name: "عدلت بتاريخ",
    },
  ];

  const queryClient = useQueryClient();

  const { data: products } = useQuery({
    queryFn: () => Axios.get(`${PRO}`),
    queryKey: ["dashProducts"],
  });

  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: async (id) => await Axios.delete(`${PRO}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashProducts"]);
    },
  });

  const { mutateAsync: postOperation } = useMutation({
    mutationFn: async (formData) => await Axios.post(`${PRO}`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashProducts"]);
    },
  });

  const handleDuplicate = async (id) => {
    setDuplicateLoading(true);
    const product = await Axios.get(`${PRO}/${id}`);
    const productData = product.data[0];
    const formData = new FormData();
    formData.append("title_en", productData.title_en);
    formData.append("title_ar", productData.title_ar);
    formData.append("description_en", productData.description_en);
    formData.append("description_ar", productData.description_ar);
    formData.append("price", productData.price);
    formData.append("discount", productData.discount);
    formData.append("price_suadia", productData.price_suadia);
    formData.append("discount_suadia", productData.discount_suadia);
    formData.append("price_emarat", productData.price_emarat);
    formData.append("discount_emarat", productData.discount_emarat);
    formData.append("price_dollar", productData.price_dollar);
    formData.append("discount_dollar", productData.discount_dollar);
    formData.append("coming_soon", productData.coming_soon);
    for (let i = 0; i < productData.colors.length; i++) {
      formData.append(`colors[${i}][name_ar]`, productData.colors[i].name_ar);
      formData.append(`colors[${i}][name_en]`, productData.colors[i].name_en);
      formData.append(`colors[${i}][quantity]`, productData.colors[i].quantity);
      formData.append(`colors[${i}][color]`, productData.colors[i].color);
      formData.append(`colors[${i}][image]`, productData.colors[0].url);
      for (let j = 0; j < productData.colors[i].product_image.length; j++) {
        formData.append(
          `colors[${i}][images][]`,
          productData.colors[i].product_image[j].url
        );
      }
    }
    try {
      const res = await postOperation(formData);
    } catch (err) {
    } finally {
      setDuplicateLoading(false);
    }
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center p-3">
        <h2 className="line">صفحة المنتجات</h2>
        <Link to={"/dashboard/product/add"}>
          <Button variant="primary">انشاء منتج جديد</Button>
        </Link>
      </div>
      <div className="m-3">
        <TableShow
          best
          showDublicateaction
          action
          actionEdit
          showDublicate
          header={header}
          data={products?.data}
          delete={mutateAsync}
          searchItem="title_en"
          handleDuplicate={handleDuplicate}
          duplicateLoading={duplicateLoading}
          deleteLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
}
