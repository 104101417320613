import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputImage from "../../../Components/Dashboard/Inputs/InputImage";
import { Axios } from "../../../Api/axios";
import { ABOUT, ABOUT1 } from "../../../Api/api";

export default function AboutUsDash() {
  // State Form
  const [form, setForm] = useState({
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    image: "",
  });
  // Handle Hange Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  const openImage = useRef();
  const handleOpenImage = () => {
    openImage.current.click();
  };

  useEffect(() => {
    Axios.get(`${ABOUT}`).then((res) =>
      setForm(res.data[0] === null ? "" : res.data[0])
    );
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title_en", form.title_en);
    formData.append("title_ar", form.title_ar);
    formData.append("description_en", form.description_en);
    formData.append("description_ar", form.description_ar);
    formData.append("image", form.image);
    try {
      const res = await Axios.post(`${ABOUT1}`, formData);
    } catch (err) {}
  }
  return (
    <div className="home-dash w-100">
      <div className="p-4">
        <h2 className="line">تعديل صفحة من نحن </h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>عنوان صفحة من نحن بالعربي</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_ar}
              name={"title_ar"}
              type="text"
              placeholder={"أدخل عنوان المنتج باللغة العربية..."}
              ref={focus}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_en">
            <Form.Label>عنوان صفحة من نحن بالإنكليزي</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_en}
              name={"title_en"}
              type="text"
              placeholder={"أدخل عنوان المنتج باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDisc_ar">
            <Form.Label>وصف صفحة من نحن بالعربي</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.description_ar}
              name={"description_ar"}
              type="text"
              placeholder={"أدخل وصف صفحة من نحن باللغة العربية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDisc_en">
            <Form.Label>وصف صفحة من نحن بالإنكليزي</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.description_en}
              name={"description_en"}
              type="text"
              placeholder={"أدخل وصف صفحة من نحن باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group className="mb-0" controlId="formBasicImage">
            <Form.Label>صورة المنتج :</Form.Label>
            <Form.Control
              ref={openImage}
              onChange={(e) => setForm({ ...form, image: e.target.files[0] })}
              hidden
              type="file"
            />
          </Form.Group>
          <InputImage
            label="Upload Image"
            getData
            handleOpenImage={() => handleOpenImage()}
          />
          {form.image && (
            <div className="d-flex flex-column align-items-start justify-content-start gap-2 my-3  position-relative">
              <img
                src={
                  typeof form.image === "object"
                    ? URL.createObjectURL(form.image)
                    : form.image
                }
                alt="icon-img"
                width={"20%"}
              />
            </div>
          )}

          <Button
            // disabled={disableBtn()}
            className="mt-3"
            variant="primary"
            type="submit"
          >
            {"حفظ "}
          </Button>
        </Form>
      </div>
    </div>
  );
}
