import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";

import RequireAuth from "./Pages/Auth/RequireAuth";
import Settings from "./Pages/Dashboard/Settings/Settings";
import ScrollToTop from "./Components/ScrollToTop";
import Page404 from "./Components/Web/ERROR/Page404";
import Products from "./Pages/Dashboard/Products/Products";
import AddProduct from "./Pages/Dashboard/Products/AddProduct";
import UpdateProduct from "./Pages/Dashboard/Products/UpdateProduct";
import Home from "./Pages/Website/Home/Home";
import ProductPage from "./Pages/Website/ProductPage/ProductPage";
import Website from "./Pages/Website/Website";
import Checkout from "./Pages/Website/Payment/Checkout";
import HomeDash from "./Pages/Dashboard/Home/HomeDash";
import Contact from "./Pages/Website/Contact/ContactUs";
import AboutUs from "./Pages/Website/About/AboutUs";
import DashFooter from "./Pages/Dashboard/Footer/DashFooter";
import Banner from "./Pages/Dashboard/Banner/Banner";
import AboutUsDash from "./Pages/Dashboard/AboutUs/AboutUsDash";
import Copouns from "./Pages/Dashboard/Copuons/Copouns";
import Subscribers from "./Pages/Dashboard/Subscribers/Subscribers";
import RequireBack from "./Pages/Auth/RequireBack";
import AddBanner from "./Pages/Dashboard/Banner/AddBanner";
import DataOneCheckout from "./Pages/Dashboard/Home/DataOneCheckout";
import UpdateBanner from "./Pages/Dashboard/Banner/UpdateBanner";
import ProductsComingSoon from "./Pages/Dashboard/ComingSoon/ProductsComingSoon";
import UpdateProductsComingSoon from "./Pages/Dashboard/ComingSoon/UpdateProductsComingSoon";
import AddProductsComingSoon from "./Pages/Dashboard/ComingSoon/AddProductsComingSoon";
import UpdateCoupon from "./Pages/Dashboard/Copuons/UpdateCoupon";
import AddCoupon from "./Pages/Dashboard/Copuons/AddCoupon";
import Transaction from "./Pages/Website/Payment/Transaction";
import RequireTransactionBack from "./Pages/Auth/RequireTransactionBack";

export default function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Website />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/transaction" element={<Transaction />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/Login" element={<Login />} />
        </Route>

        <Route path="*" element={<Page404 />} />
        <Route element={<RequireBack />}>
          <Route path="/checkout" element={<Checkout />} />
        </Route>
        <Route element={<RequireTransactionBack />}>
          <Route path="/transaction" element={<Transaction />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="home" element={<HomeDash />} />
            <Route path="home/:id" element={<DataOneCheckout />} />
            <Route path="coupons" element={<Copouns />} />
            <Route path="coupons/add" element={<AddCoupon />} />
            <Route path="coupons/:id" element={<UpdateCoupon />} />
            <Route path="subscribers" element={<Subscribers />} />
            <Route path="banner" element={<Banner />} />
            <Route path="banner/:id" element={<UpdateBanner />} />
            <Route path="about-us" element={<AboutUsDash />} />
            <Route path="products" element={<Products />} />
            <Route path="products/:id" element={<UpdateProduct />} />
            <Route path="product/add" element={<AddProduct />} />
            <Route
              path="products-coming-soon"
              element={<ProductsComingSoon />}
            />
            <Route
              path="products-coming-soon/:id"
              element={<UpdateProductsComingSoon />}
            />
            <Route
              path="products-coming-soon/add"
              element={<AddProductsComingSoon />}
            />
            <Route path="banner/add" element={<AddBanner />} />
            <Route path="footer" element={<DashFooter />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}
