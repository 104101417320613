import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./product.css";
import { useContext } from "react";
import { Lang } from "../../../Context/LangContext";

export default function ProductComingSoon(props) {
  const { lang } = useContext(Lang);

  const typography = lang === "ar" ? "f-cairo" : "f-pop";

  return (
    <div
      className={`${props.className} text-${lang === "ar" ? "end" : "start"}`}
    >
      <div className="h-100 m-1 bg-custom-grey position-relative d-block text-decoration-none product-card">
        <div
          className={`position-absolute top-0 ${
            lang === "en" ? "start" : "end"
          }-0 ${typography} p-2 bg-secondary`}
        >
          <p className="m-0 text-white">
            {lang === "en" ? "Coming Soon!" : "!سيتوفر قريبا"}
          </p>
        </div>

        <img
          src={props.img}
          alt="img"
          className="img-fluid"
          style={{ height: "200px", objectFit: "cover", width: "100%" }}
        />
        <div className=" pt-5 text-black px-4">
          <h6 className={`${typography} text-truncate`}>
            {lang === "en" ? props.title : props.title_ar}
          </h6>
          <h6
            dir={lang === "ar" && "rtl"}
            className={`${typography} text-secondary text-truncate`}
          >
            {lang === "en" ? props.description : props.description_ar}
          </h6>
        </div>
        <div
          className="d-flex align-items-center flex-wrap gap-3 px-4 pt-3 pb-3"
          dir={lang === "ar" && "rtl"}
        >
          <div
            className="rounded-pill"
            style={{
              height: "20px",
              width: "40px",
              backgroundColor: props.color,
              cursor: "pointer",
              border: "2px solid white",
              outline: `2px solid ${props.color}`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
