import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ProductItme from "../Modal/ProductItem";
import { Lang } from "../../../Context/LangContext";
import { Cart } from "../../../Context/CartContext";

export default function ModalShoping() {
  const nav = useNavigate();
  const [getProducts, setGetProducts] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const { cart, setCart, setTotal, setGetData, getData } = useContext(Cart);
  //  lang
  const language = useContext(Lang);

  useEffect(() => {
    setGetProducts(
      localStorage.getItem("products")
        ? JSON.parse(localStorage.getItem("products"))
        : []
    );
  }, [cart, getData]);

  function handleSubTotal() {
    handleQuantities();
    let products = JSON.parse(localStorage.getItem("products")) || [];
    let subTotal = 0;
    let prev = 0;
    for (let i = 0; i < products.length; i++) {
      const element = products[i];
      prev = element.quantity * element.price;
      subTotal += prev;
    }
    setSubTotal(subTotal);
    setTotal(subTotal);
    localStorage.setItem("products", JSON.stringify(products));
  }

  const handleQuantities = () => {
    let products = JSON.parse(localStorage.getItem("products")) || [];
    let subTotal = 0;
    let prev = 0;
    for (let i = 0; i < products.length; i++) {
      const element = products[i];
      prev = element.quantity;
      subTotal += prev;
    }
    setQuantity(subTotal);
  };

  // Handle Delete Item
  const handleDeleteItem = (index) => {
    let products = JSON.parse(localStorage.getItem("products")) || [];
    let productsIds = JSON.parse(localStorage.getItem("productsIds")) || [];
    let colorIds = JSON.parse(localStorage.getItem("colorIds")) || [];
    let quantity = JSON.parse(localStorage.getItem("quantity")) || [];
    let filtredProducts = products.filter((item, key) => key !== index);
    let productsIdsProducts = productsIds.filter((item, key) => key !== index);
    let colorIdsProducts = colorIds.filter((item, key) => key !== index);
    let quantityProducts = quantity.filter((item, key) => key !== index);
    setGetProducts((prev) => prev.filter((item, key) => key !== index));
    localStorage.setItem("products", JSON.stringify(filtredProducts));
    localStorage.setItem("productsIds", JSON.stringify(productsIdsProducts));
    localStorage.setItem("colorIds", JSON.stringify(colorIdsProducts));
    localStorage.setItem("quantity", JSON.stringify(quantityProducts));
    setCart((prev) => prev - 1);
    setGetData((prev) => prev + 1);
    handleSubTotal();
  };

  // Handle Change Language
  function handleLangChange(lang) {
    localStorage.setItem("lang", lang);
    language.setLang(lang);
  }

  return (
    <>
      <div className="pointer d-flex align-items-center gap-4">
        <div
          data-bs-toggle="modal"
          className="position-relative"
          data-bs-target="#staticBackdrop"
        >
          <div
            className="position-absolute bg-custom-grey rounded-circle d-flex align-items-center justify-content-center bg-custom-red text-white"
            style={{
              top: "-12px",
              right: "-12px",
              width: "21px",
              height: "21px",
              backgroundColor: "",
            }}
          >
            <p className="m-0" style={{ fontSize: "12px" }}>
              {quantity}
            </p>
          </div>
          <span className="me-1 primary">{subTotal}</span>
          <FontAwesomeIcon
            className="primary"
            fontSize="22px"
            icon={faBagShopping}
          />
        </div>
        <div className="d-flex align-items-center gap-4">
          {language.lang === "en" ? (
            <h5
              style={{ cursor: "pointer", userSelect: "none" }}
              onClick={() => handleLangChange("ar")}
              className="m-0 gray"
            >
              Ar
            </h5>
          ) : (
            <h5
              style={{ cursor: "pointer", userSelect: "none" }}
              onClick={() => handleLangChange("en")}
              className="m-0 gray"
            >
              En
            </h5>
          )}
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              dir={language.lang === "en" ? "ltr" : "rtl"}
              className="modal-header d-flex justify-content-between"
            >
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {language.lang === "en" ? "Shopping Cart" : "سلة الشراء"}
              </h1>
              <button
                style={{ margin: language.lang === "ar" && "0 auto 0 0" }}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {getProducts.map((item, index) => (
              <ProductItme
                key={index}
                index={index}
                title={item.title}
                title_ar={item.title_ar}
                img={item.img}
                quantity={item.quantity}
                price={item.price}
                handleDeleteItem={handleDeleteItem}
                handleSubTotal={handleSubTotal}
                setGetProducts={setGetProducts}
                getProducts={getProducts}
              />
            ))}
            <div
              className="d-flex align-items-center justify-content-between p-3 gray"
              style={{ border: "1px solid #dee2e6" }}
            >
              <span>Subtotal :</span>
              <span>{subTotal}</span>
            </div>
            <div className="modal-footer mx-0">
              <Link
                to={"/checkout"}
                className="p-2 px-4 w-100 border-0 btn-modal bg-custom-red bg-custom-red text-uppercase text-center text-decoration-none gray text-white"
                data-bs-dismiss="modal"
                onClick={() => nav("/checkout")}
              >
                CheckOut
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
