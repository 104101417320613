export const baseURL = "https://backend.mysajda.com/api/";
export const LOGIN = "login";

export const HOME = "home";
// User
export const USER = "user";
export const EDIT = "edit";
export const LOGOUT = "logout";

// Products
export const PRO = "product";
// Products Coming Soon
export const COMING = "coming-soon";

// Footer
export const FOOTER = "footer";

// Banner
export const BANNER = "banner";

// Contact
export const CONTACT = "contact";

// About
export const ABOUT = "about";
export const ABOUT1 = "about/1";

// Payment
export const CHECKOUT = "checkout";
export const CHANGE = "change";
export const PaymentTest = "payment-test";
export const GetPayment = "get-payment";
export const CheckQuantity = "check-quantity";
// Coupons
export const COUPON = "coupon";
export const CHECKCOUPON = "check-coupon";

// Subscribers
export const SubscribersAPI = "subscribe";
