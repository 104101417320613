import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import { Axios } from "../../../Api/axios";
import { EDIT, USER } from "../../../Api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";

export default function Settings() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [getEmail, setGetEmail] = useState("");
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [err, setErr] = useState("");
  const [error, setError] = useState(false);
  const [SkeletonSt, setSkeleton] = useState(true);

  //   Get Data
  useEffect(() => {
    async function getData() {
      const res = await Axios.get(`${USER}`);
      setUser({ ...user, email: res.data.email, name: res.data.name });
      setGetEmail(res.data.email);
      setSkeleton(false);
    }
    getData();
  }, []);

  //   Handle Change
  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value });
  }
  const focus = useRef("");
  // const focusInput = focus.current.focus();

  // Handle Edit User
  async function handleEditUser(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await Axios.post(`${USER}/${EDIT}`, user);
      setLoading(false);
      // Start Handle Alert
      setAlert(true);
      document.querySelector(".tes").style.transform = "translateX(100%)";
      setTimeout(() => {
        setAlert(false);
        document.querySelector(".tes").style.transform = "translateX(0)";
      }, 3000);
      document.querySelector(".tes").style.transform = "translateX(105%)";
      // End Handle Alert
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div
        onClick={() => {
          setAlert(false);
        }}
        style={{
          transform: alert ? "translateY(0)" : "translateY(-120px)",
          opacity: alert ? "1" : "0",
          transition: "0.5s",
          zIndex: 3,
          cursor: "pointer",
        }}
        className=" position-fixed start-0 mt-3 ms-3 shadow col-md-5"
      >
        <Alert
          variant="success"
          className="d-flex align-items-center justify-content-between position-relative h-100 mb-0 overflow-hidden"
        >
          <p className="m-0"> تم التعديل بنجاح</p>
          <FontAwesomeIcon icon={faCheck} />
          <div
            className="w-100 position-absolute start-0 bottom-0 tes"
            style={{
              width: "100%",
              height: "3px",
              transition: "3s ease",
              backgroundColor: "green",
            }}
          ></div>
        </Alert>
      </div>
      <div
        className="home-dash w-100"
        style={{
          transition: "0.3s",
        }}
      >
        <div className="p-4">
          <h2 className="line">الإعدادات</h2>
        </div>
        <div className="m-3 mb-4 shadow bg-white p-3 rounded">
          <Form onSubmit={handleEditUser}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>الإسم :</Form.Label>
              {SkeletonSt ? (
                <div className="col-12 col-lg">
                  <Skeleton height={"30px"} />
                </div>
              ) : (
                <Form.Control
                  onChange={handleChange}
                  value={user.name}
                  name={"name"}
                  type="text"
                  placeholder="اسم المستخدم..."
                  required
                  ref={focus}
                />
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>الإيميل :</Form.Label>
              {SkeletonSt ? (
                <div className="col-12 col-lg">
                  <Skeleton height={"30px"} />
                </div>
              ) : (
                <Form.Control
                  onChange={handleChange}
                  value={user.email}
                  name={"email"}
                  type="email"
                  placeholder="الأيميل..."
                  required
                />
              )}
            </Form.Group>
            {error && user.email === getEmail && (
              <p className="text-danger">البريد الإلكتروني تم أخذه</p>
            )}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>كلمة السر :</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={user.password}
                name={"password"}
                type="password"
                placeholder="كلمة السر..."
                required
                minLength={6}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicPasswordConfirmation"
            >
              <Form.Label>تأكيد كلمة السر :</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={user.password_confirmation}
                name={"password_confirmation"}
                type="password"
                placeholder="تأكيد كلمة السر..."
                required
                minLength={6}
              />
            </Form.Group>
            {error && user.password !== user.password_confirmation && (
              <p className="text-danger">تأكيد كلمة المرور غير متطابق</p>
            )}
            {/* {error && <p className="text-danger">{err}</p>} */}
            <Button
              variant="primary"
              type="submit"
              disabled={
                user.name === "" ||
                user.email === "" ||
                user.password === "" ||
                error & (user.password !== user.password_confirmation) ||
                user.email === getEmail
              }
            >
              حفظ
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}
