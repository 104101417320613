import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { LOGIN, baseURL } from "../../Api/api";
import Cookie from "cookie-universal";
import Alert from "react-bootstrap/Alert";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const nav = useNavigate("");
  const cookie = Cookie();
  // Handle Change Input
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }
  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${baseURL}${LOGIN}`, form);
      const token = res.data.data.token;
      cookie.set("Bearer", token);
      setLoading(false);
      window.location.pathname = "/dashboard/home";
    } catch (err) {
      setLoading(false);
      if (err.response.status === 401) {
        setErr("Email or Password is not Correct");
      } else {
        setErr("Internal Server ERR");
      }
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div
        className="w-50 d-flex justify-content-center align-items-center m-auto "
        style={{ height: "100vh" }}
      >
        <div className="w-100 shadow p-3">
          <h1>Login Now</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email :</Form.Label>
              <Form.Control
                onChange={handleChange}
                type="email"
                placeholder="Email.."
                value={form.email}
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password :</Form.Label>
              <Form.Control
                onChange={handleChange}
                type="password"
                value={form.password}
                placeholder="Password..."
                name="password"
              />
            </Form.Group>
            {err !== "" && <Alert variant="danger">{err}</Alert>}
            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}
