import { useEffect, useRef, useState } from "react";
import { Axios } from "../../../Api/axios";
import { CHANGE, CHECKOUT } from "../../../Api/api";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import Skeleton from "react-loading-skeleton";

export default function DataOneCheckout() {
  const [form, setForm] = useState({
    name: "",
    surename: "",
    mobile_number: "",
    email: "",
    created_at: "",
    time: "",
    coupon: "",
    country: "",
    full_address: "",
    payment_method: "",
    code_number: "",
    hours: "",
  });
  const [changeStatus, setChangeStatus] = useState({
    status: "",
    refuse_reason: "",
  });
  const [loading, setLoading] = useState(false);
  const nav = useNavigate("");
  const { id } = useParams();

  // Get Product Information
  useEffect(() => {
    setLoading(true);
    async function getData() {
      await Axios.get(`${CHECKOUT}/${id}`).then((res) => {
        const dateString = res.data[0]?.checkout?.created_at;
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        setForm({
          name: res.data[0]?.checkout?.name,
          surename: res.data[0]?.checkout?.surename,
          mobile_number: res.data[0]?.checkout?.mobile_number,
          email: res.data[0]?.checkout?.email,
          created_at: new Date(
            res.data[0]?.checkout?.created_at
          ).toLocaleDateString(),
          coupon: res.data[0]?.checkout?.coupon,
          country: res.data[0]?.checkout?.country,
          full_address: res.data[0]?.checkout?.full_address,
          payment_method: res.data[0]?.checkout?.payment_method,
          code_number: res.data[0]?.checkout?.code_number,
          hours: hours + ":" + minutes,
        });
        setChangeStatus({
          ...changeStatus,
          status: res.data[0]?.checkout?.status,
        });
        setLoading(false);
        if (form.created_at !== "") {
          const hour =
            new Date(form.created_at).getHours() +
            ":" +
            new Date(form.created_at).getMinutes() +
            ":" +
            new Date(form.created_at).getSeconds();
          setForm({ ...form, time: hour });
        }
      });
    }
    getData();
  }, []);

  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await Axios.post(`${CHECKOUT}/${CHANGE}/${id}`, changeStatus);
      setLoading(false);
      nav("/dashboard/home");
    } catch (err) {
      setLoading(false);
    }
  }
  return (
    <div className="home-dash w-100">
      <div className="p-4">
        <h2 className="line">بيانات الطلب</h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> رقم مرجعي : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.code_number}
                name={"code_number"}
                type="text"
                placeholder={" الرقم المرجعي..."}
                ref={focus}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>الأسم الأول : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.name}
                name={"title_ar"}
                type="text"
                placeholder={"الأسم الأول ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>الأسم الأخير : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.surename}
                name={"title_ar"}
                type="text"
                placeholder={"الأسم الأخير ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> البريد الألكتروني : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.email}
                name={"title_ar"}
                type="text"
                placeholder={"البريد الألكتروني ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> رقم الهاتف : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.mobile_number}
                name={"title_ar"}
                type="text"
                placeholder={"رقم الهاتف ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> البريد الألكتروني : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.email}
                name={"title_ar"}
                type="text"
                placeholder={"البريد الألكتروني ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> الدولة : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.country}
                name={"title_ar"}
                type="text"
                placeholder={" الدولة ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> العنوان الكامل : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.full_address}
                name={"title_ar"}
                type="text"
                placeholder={" العنوان الكامل ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> كوبون الحسم : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.coupon}
                name={"title_ar"}
                type="text"
                placeholder={" لا يوجد كوبون حسم..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> طريقة الدفع : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.payment_method}
                name={"title_ar"}
                type="text"
                placeholder={" طريقة الدفع..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> انشأت بتاريخ : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.created_at}
                name={"title_ar"}
                type="text"
                placeholder={"  انشأت بتاريخ..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label> الساعة : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                value={form.hours}
                name={"title_ar"}
                type="text"
                placeholder={"   الساعة..."}
              />
            )}
          </Form.Group>
          <div className="mb-3 d-flex align-items-center gap-3">
            <div className="d-flex align-items-center gap-1">
              <input
                onChange={(e) =>
                  setChangeStatus({ ...changeStatus, status: "processing" })
                }
                value={changeStatus.status}
                type="radio"
                name="status"
                id="radio-1"
                checked={changeStatus.status === "processing" && true}
              />
              <label htmlFor="radio-1">قيد التقدم</label>
            </div>
            <div className="d-flex align-items-center gap-1">
              <input
                onChange={(e) =>
                  setChangeStatus({ ...changeStatus, status: "completed" })
                }
                value={changeStatus.status}
                type="radio"
                name="status"
                id="radio-2"
                checked={changeStatus.status === "completed" && true}
              />
              <label htmlFor="radio-2">مكتمل</label>
            </div>
            <div className="d-flex align-items-center gap-1">
              <input
                onChange={(e) =>
                  setChangeStatus({ ...changeStatus, status: "canceled" })
                }
                value={changeStatus.status}
                type="radio"
                name="status"
                id="radio-3"
                checked={changeStatus.status === "canceled" && true}
              />
              <label htmlFor="radio-3">ملغي </label>
            </div>
          </div>
          {changeStatus.status === "canceled" && (
            <Form.Group className="mb-3" controlId="formBasicRefuse_reason">
              <Form.Label> سبب الإلغاء : </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setChangeStatus({
                    ...changeStatus,
                    refuse_reason: e.target.value,
                  })
                }
                value={changeStatus.refuse_reason}
                name={"refuse_reason"}
                type="text"
                placeholder={"اكتب سبب الإلغاء..."}
              />
            </Form.Group>
          )}
          {loading ? (
            <div
              style={{ width: "86px", height: "37px" }}
              className="bg-main-color mt-3 py-3 rounded d-flex align-items-center justify-contnet-center"
            >
              <LoadingBtn />
            </div>
          ) : (
            <Button className="mt-3" variant="primary" type="submit">
              {"حفظ التعديلات "}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}
