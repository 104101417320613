import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Axios } from "../../../Api/axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { COMING, PRO } from "../../../Api/api";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import InputImage from "../../../Components/Dashboard/Inputs/InputImage";

export default function AddProductsComingSoon() {
  // State Form
  const [form, setForm] = useState({
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    color: "#000",
    image: "",
  });

  // Loading
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  // Handle Hange Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("title_en", form.title_en);
    formData.append("title_ar", form.title_ar);
    formData.append("description_en", form.description_en);
    formData.append("description_ar", form.description_ar);
    formData.append("color", form.color);
    formData.append("img", form.image);
    try {
      const res = await Axios.post(`${COMING}`, formData);
      nav("/dashboard/products-coming-soon");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  // Habdel Focus on input file
  const openImage = useRef();
  const handleOpenImage = () => {
    openImage.current.click();
  };

  return (
    <div className="home-dash w-100">
      <div className="p-4">
        <h2 className="line">إضافة المنتج</h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicTitle_ar">
            <Form.Label>عنوان المنتج باللغة العربية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_ar}
              name={"title_ar"}
              type="text"
              placeholder={"أدخل عنوان المنتج باللغة العربية..."}
              ref={focus}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTitle">
            <Form.Label>عنوان المنتج باللغة الإنكليزية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.title_en}
              name={"title_en"}
              type="text"
              placeholder={"أدخل عنوان المنتج باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDisc_ar">
            <Form.Label>وصف المنتج باللغة العربية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.description_ar}
              name={"description_ar"}
              type="text"
              placeholder={"أدخل وصف المنتج باللغة العربية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDisc">
            <Form.Label>وصف المنتج باللغة الإنكليزية</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={form.description_en}
              name={"description_en"}
              type="text"
              placeholder={"أدخل وصف المنتج باللغة الإنكليزية..."}
            />
          </Form.Group>
          <Form.Group className="mb-3 d-flex align-items-center gap-4">
            <label htmlFor="hex-color">تحديد اللون التقريبي للمنتج :</label>
            <input
              type="color"
              name="color"
              id="hex-color"
              value={form.color}
              onChange={handleChange}
              className="pointer"
            />
          </Form.Group>
          <Form.Group className="mb-0" controlId="formBasicImage">
            <Form.Label>صورة المنتج :</Form.Label>
            <Form.Control
              ref={(e) => (openImage.current = e)}
              onChange={(e) => setForm({ ...form, image: e.target.files[0] })}
              hidden
              type="file"
            />
          </Form.Group>
          <InputImage
            label="Upload Image"
            getData
            handleOpenImage={() => handleOpenImage()}
          />
          {form.image && (
            <div className="d-flex flex-column align-items-start justify-content-start gap-2 my-3  position-relative">
              <img
                src={
                  typeof form.image === "object"
                    ? URL.createObjectURL(form.image)
                    : form.image
                }
                alt="icon-img"
                width={"20%"}
              />
            </div>
          )}
          {loading ? (
            <div
              style={{ width: "86px", height: "37px" }}
              className="bg-main-color mt-3 py-3 rounded d-flex align-items-center justify-contnet-center"
            >
              <LoadingBtn />
            </div>
          ) : (
            <Button
              disabled={
                form.image === "" ||
                form.title_ar === "" ||
                form.title_en === "" ||
                form.description_ar === "" ||
                form.description_en === ""
              }
              className="mt-3"
              variant="primary"
              type="submit"
            >
              {"إضافة المنتج "}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}
