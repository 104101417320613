import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Axios } from "../../../Api/axios";
import { FOOTER } from "../../../Api/api";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";

export default function DashFooter() {
  const [form, setForm] = useState({
    title_en: "",
    title_ar: "",
    icon_en: "",
    icon_ar: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    phone: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const regexNumber = /^[\d\s+]+$/;

  // Handle Change Form
  function handleChange(e) {
    if (e.target.files) {
      setForm({ ...form, [e.target.name]: e.target.files[0] });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  }
  // Get Data
  useEffect(() => {
    Axios.get(`${FOOTER}`).then((res) =>
      setForm(res.data[0] === null ? "" : res.data[0])
    );
  }, []);
  // Handle Submit
  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("title_en", form.title_en);
    formData.append("title_ar", form.title_ar);
    formData.append("icon_en", form.icon_en);
    formData.append("icon_ar", form.icon_ar);
    formData.append("facebook", form.facebook);
    formData.append("twitter", form.twitter);
    formData.append("instagram", form.instagram);
    formData.append("youtube", form.youtube);
    formData.append("phone", form.phone);
    formData.append("email", form.email);

    try {
      if (regexNumber.test(form.phone)) {
        await Axios.post(`${FOOTER}`, formData);
        setFlag(false);
        window.location.reload();
      } else {
        setFlag(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center p-3">
        <h2 className="line">تعديل بيانات الفوتر</h2>
      </div>
      <div className="m-3">
        <div className="bg-white p-3 rounded shadow-sm">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicTitle_ar">
              <Form.Label>العنوان في اللغة العربي</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.title_ar}
                name={"title_ar"}
                type="text"
                placeholder={"أدخل العنوان باللغة العربية..."}
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="formBasicTitle_en">
              <Form.Label>العنوان في اللغة الإنجليزية</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.title_en}
                name={"title_en"}
                type="text"
                placeholder={"أدخل العنوان باللغة الإنجليزية..."}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicIconAr">
              <Form.Label>الأيقونة في حالة الموقع باللغة العربية</Form.Label>
              <Form.Control
                onChange={handleChange}
                name={"icon_ar"}
                type="file"
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="formBasicIconEn">
              <Form.Label>الأيقونة في حالة الموقع باللغة الإنجليزية</Form.Label>
              <Form.Control
                onChange={handleChange}
                name={"icon_en"}
                type="file"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicfacebook">
              <Form.Label> رابط فيسبوك (اختياري)</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.facebook}
                name={"facebook"}
                type="text"
                placeholder={"اتركه فارغا كي لايظهر بالموقع..."}
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="formBasicInstagram">
              <Form.Label> رابط انستغرام (اختياري)</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.instagram}
                name={"instagram"}
                type="text"
                placeholder={"اتركه فارغا كي لايظهر بالموقع..."}
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="formBasictwitter">
              <Form.Label> رابط تويتر (اختياري)</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.twitter}
                name={"twitter"}
                type="text"
                placeholder={"اتركه فارغا كي لايظهر بالموقع..."}
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="formBasicyoutube">
              <Form.Label> رابط يوتيوب (اختياري)</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.youtube}
                name={"youtube"}
                type="text"
                placeholder={"اتركه فارغا كي لايظهر بالموقع..."}
              />
            </Form.Group>{" "}
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicPhone"
            >
              <Form.Label> رقم الهاتف (اختياري)</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.phone}
                name={"phone"}
                type="text"
                placeholder={"اتركه فارغا كي لايظهر بالموقع..."}
              />
              {flag && !regexNumber.test(form.phone) && (
                <p className={`position-absolute top-100 end-0  text-danger`}>
                  الرجاء إدخال ارقام وفراغات وعلامة + فقط
                </p>
              )}
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> البريد الإلكتروني (اختياري)</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={form.email}
                name={"email"}
                type="text"
                placeholder={"اتركه فارغا كي لايظهر بالموقع..."}
              />
            </Form.Group>{" "}
            {loading ? (
              <LoadingBtn />
            ) : (
              <Button
                type="submit"
                disabled={form.title_ar === "" || form.title_en === ""}
              >
                حفظ
              </Button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
