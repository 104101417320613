import {
  faCheck,
  faF,
  faGear,
  faHouse,
  faImages,
  faAddressCard,
  faTags,
  faUsers,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";

export const DashLinks = [
  {
    name: "الطلبات",
    path: "/dashboard/home",
    icon: faHouse,
  },
  {
    name: "المشتركين",
    path: "/dashboard/subscribers",
    icon: faUsers,
  },
  {
    name: "المنتجات",
    path: "/dashboard/products",
    icon: faCheckDouble,
  },
  {
    name: "ستتوفر قريبا",
    path: "/dashboard/products-coming-soon",
    icon: faCheck,
  },
  {
    name: "الكوبونات",
    path: "/dashboard/coupons",
    icon: faTags,
  },

  {
    name: "البنر",
    path: "/dashboard/banner",
    icon: faImages,
  },
  {
    name: "من نحن",
    path: "/dashboard/about-us",
    icon: faAddressCard,
  },
  {
    name: "الفوتر",
    path: "/dashboard/footer",
    icon: faF,
  },

  {
    name: "الإعدادت",
    path: "/dashboard/settings",
    icon: faGear,
  },
];
