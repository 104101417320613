import { createContext, useState } from "react";

export const Lang = createContext("");

export default function LangContext({ children }) {
  const defaultLang = localStorage.getItem("lang");

  const [lang, setLang] = useState(defaultLang !== null ? defaultLang : "en");

  return <Lang.Provider value={{ lang, setLang }}>{children}</Lang.Provider>;
}
