import { useContext } from "react";
import { WindowSize } from "../../../Context/WindowContext";
import { Menu } from "../../../Context/MenuContext";
import { DashLinks } from "./DashLinks";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./bars.css";

export default function SideBarDash() {
  const WindowContext = useContext(WindowSize);
  const windowSize = WindowContext.windowSize;
  const menu = useContext(Menu);
  const isOpen = menu.isOpen;
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "70px",
          right: "0",
          width: "100%",
          height: "100vh",
          zIndex: 1,
          backgroundColor: "rgba(0,0,0,0.2)",
          display: windowSize < "768" && isOpen ? "block" : "none",
        }}
      ></div>
      <div
        className="side-bar pt-3"
        style={{
          right: windowSize < "768" ? (isOpen ? 0 : "-100%") : 0,
          width: isOpen ? "220px" : "fit-content",
          position: windowSize < "768" ? "fixed" : "fixed",
        }}
      >
        {DashLinks.map((link, key) => (
          <NavLink
            key={key}
            to={link.path}
            className={"side-bar-link d-flex align-items-center gap-2"}
            style={{
              padding: isOpen ? "10px 20px" : "10px 13px",
              textDecoration: "none",
            }}
          >
            <FontAwesomeIcon icon={link.icon} />
            <p className="mb-0" style={{ display: isOpen ? "block" : "none" }}>
              {link.name}
            </p>
          </NavLink>
        ))}
      </div>
    </>
  );
}
