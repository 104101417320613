import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import { Axios } from "../../../Api/axios";
import { useNavigate, useParams } from "react-router-dom";
import { COUPON } from "../../../Api/api";
import Skeleton from "react-loading-skeleton";

export default function UpdateCoupon() {
  const [form, setForm] = useState({
    name_en: "",
    name_ar: "",
    coupon: "",
    discount: "",
    expires_at: "",
  });
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState("");
  const [loading, setLoading] = useState(false);
  const nav = useNavigate("");
  const { id } = useParams();
  useEffect(() => {
    // الحصول على تاريخ اليوم
    const today = new Date().toISOString().split("T")[0];
    // تعيين قيمة اليوم كقيمة الحد الأدنى لحقل التاريخ
    setMinDate(today);
  }, []);
  // Handle Hange Form
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }
  // Handle Get single Coupon
  useEffect(() => {
    setLoading(true);
    Axios.get(`${COUPON}/${id}`).then((res) => {
      setForm({
        name_en: res.data?.name_en,
        name_ar: res.data?.name_ar,
        coupon: res.data?.coupon,
        discount: res.data?.discount,
        expires_at: res.data?.expires_at,
      });
      setLoading(false);
    });
  }, []);
  // Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await Axios.post(`${COUPON}/${id}`, form);
      setLoading(false);
      setForm({
        name_en: "",
        name_ar: "",
        coupon: "",
        discount: "",
        expires_at: "",
      });
      nav("/dashboard/coupons");
    } catch (err) {
      setLoading(false);
      setError("هذا الكوبون مستخدم مسبقا بالفعل");
    }
  }
  // Handle Focus
  const focus = useRef(null);
  useEffect(() => {
    focus.current.focus();
  }, []);

  // Handle scroll in input
  const inputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };
    const inputElement = inputRef.current;
    inputElement.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      inputElement.removeEventListener("wheel", handleWheel);
    };
  }, [form.discount]);

  return (
    <div className="w-100">
      <div className="p-4">
        <h2 className="line"> تعديل الكوبون </h2>
      </div>
      <div className="m-3 mb-4 shadow bg-white p-3 rounded">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName_ar">
            <Form.Label>اسم الكوبون باللغة العربية :</Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                onChange={handleChange}
                value={form.name_ar}
                name={"name_ar"}
                type="text"
                placeholder={"أدخل اسم الكوبون باللغة العربية..."}
                ref={focus}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicName_en">
            <Form.Label> اسم الكوبون باللغة الإنكليزية :</Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                onChange={handleChange}
                value={form.name_en}
                name={"name_en"}
                type="text"
                placeholder={"أدخل اسم الكوبون باللغة الإنكليزية..."}
              />
            )}
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicCoupon"
          >
            <Form.Label>رمز الكوبون : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                onChange={handleChange}
                value={form.coupon}
                name={"coupon"}
                type="text"
                placeholder={"أدخل رمز الكوبون..."}
              />
            )}
            {error !== "" && (
              <p className="position-absolute top-100 end-0 text-danger">
                {error}
              </p>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDiscount">
            <Form.Label>نسبة الحسم : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                onChange={handleChange}
                value={form.discount}
                name={"discount"}
                ref={inputRef}
                type="number"
                placeholder={"أدخل نسبة الحسم..."}
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-3" controlId="duedate">
            <Form.Label>تاريخ الإنتهاء : </Form.Label>
            {loading ? (
              <Skeleton height={"40px"} />
            ) : (
              <Form.Control
                type="date"
                placeholder="Due date"
                min={minDate}
                onChange={handleChange}
                name="expires_at"
                value={form.expires_at}
              />
            )}
          </Form.Group>
          {loading ? (
            <div
              style={{ width: "86px", height: "37px" }}
              className="bg-main-color py-3 rounded d-flex align-items-center justify-contnet-center"
            >
              <LoadingBtn />
            </div>
          ) : (
            <Button type="submit">تعديل</Button>
          )}
        </Form>
      </div>
    </div>
  );
}
