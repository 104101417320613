import { createContext, useState } from "react";

export const OnDelivery = createContext("");

export default function OnDeliveryContext({ children }) {
  const [onDelivery, setOnDelivery] = useState(false);
  return (
    <OnDelivery.Provider value={{ onDelivery, setOnDelivery }}>
      {children}
    </OnDelivery.Provider>
  );
}
