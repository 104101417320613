import Cookie from "cookie-universal";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Axios } from "../../Api/axios";
import { USER } from "../../Api/api";
import Loading from "../../Components/Loading/Loading";
export default function RequireAuth() {
  const nav = useNavigate();
  // Get User
  const [user, setUser] = useState("");
  useEffect(() => {
    Axios.get(`${USER}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch(() => nav("/login", { replace: true }));
  }, []);
  // Token & Cookie
  const cookie = Cookie();
  const token = cookie.get("Bearer");
  return token ? (
    user === "" ? (
      <Loading />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={"/login"} replace={true} />
  );
}
