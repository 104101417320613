import { useMutation, useQuery, useQueryClient } from "react-query";
import { Axios } from "../../../Api/axios";
import { CHECKOUT } from "../../../Api/api";
import CheckoutTableShow from "../../../Components/Dashboard/Table/CheckoutTable";

export default function HomeDash() {
  const header = [
    {
      key: "code_number",
      name: "رقم مرجعي",
    },
    {
      key: "name",
      name: "الاسم الاول",
    },
    {
      key: "surename",
      name: "اسم العائلة",
    },
    {
      key: "mobile_number",
      name: "رقم الهاتف",
    },
    {
      key: "created_at",
      name: "أنشأت بتاريخ",
    },
    {
      key: "created_at",
      name: "الساعة",
    },
    {
      key: "coupon",
      name: "الكوبون",
    },
    {
      key: "country",
      name: "الدولة",
    },
    {
      key: "full_address",
      name: "العنوان الكامل",
    },
    {
      key: "payment_method",
      name: "طريقة الدفع",
    },
    {
      key: "status",
      name: "الحالة",
    },
  ];
  const queryClient = useQueryClient();
  // Handle Get Data
  const { data: products } = useQuery({
    queryFn: () => Axios.get(`${CHECKOUT}`),
    queryKey: ["dashAnalyiss"],
  });
  // Handle Delete Item
  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: async (id) => await Axios.delete(`${CHECKOUT}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(["dashAnalyiss"]);
    },
  });

  return (
    <>
      <div className="w-100">
        <div className="p-3">
          <h2 className="line mb-3">جدول المبيعات</h2>
        </div>
        <div className="m-3">
          <CheckoutTableShow
            best={false}
            header={header}
            data={products?.data || []}
            searchItem="name"
            delete={mutateAsync}
            deleteLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      </div>
    </>
  );
}
