import { useContext } from "react";
import { Lang } from "../../../Context/LangContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { Axios } from "../../../Api/axios";
import { FOOTER } from "../../../Api/api";
import "./footer.css";

export default function Footer() {
  const { lang } = useContext(Lang);
  const typography = lang === "ar" ? "f-cairo" : "f-pop";

  const { data: footer } = useQuery({
    queryFn: () => Axios.get(`${FOOTER}`),
    queryKey: ["footer"],
  });
  return (
    <>
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
        <Container>
          <footer
            className={`row col-12 flex-wrap justify-content-between text-white  py-5 border-top ${typography}`}
            dir={lang === "ar" && "rtl"}
          >
            <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-between flex-wrap">
              <div className="col-md-6 col-xl-5 col-12 mb-3">
                <a
                  href="/"
                  className="d-flex align-items-center mb-3 link-dark text-decoration-none"
                >
                  {lang === "ar" ? (
                    <img
                      width={"150px"}
                      src={footer?.data?.[0]?.icon_ar}
                      alt="logo"
                    />
                  ) : (
                    <img
                      width={"150px"}
                      src={footer?.data?.[0]?.icon_en}
                      alt="logo"
                    />
                  )}
                </a>
                <p>
                  {lang === "ar"
                    ? footer?.data?.[0]?.title_ar
                    : footer?.data?.[0]?.title_en}
                </p>
              </div>
              <div className=" col-md-5 col-12 mb-3 ">
                <ul className="nav flex-column m-0 p-0 col-md-12 col-12">
                  <div className="d-flex justify-content-between flex-md-row flex-column">
                    <li className="nav-item mb-2 ms-0">
                      <Link to="/" className="nav-link p-0 ">
                        {lang === "ar" ? "الصفحة الرئيسية" : "Home"}
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link to="/about" className="nav-link p-0 ">
                        {lang === "ar" ? "من نحن" : "About Us"}
                      </Link>
                    </li>
                  </div>
                  <div className="d-flex justify-content-between flex-md-row flex-column">
                    <li className="nav-item mb-2">
                      <Link to="/" className="nav-link p-0 ">
                        {lang === "ar" ? "تسوق" : "Shop Now"}
                      </Link>
                    </li>

                    <li className="nav-item mb-2">
                      <Link to="/contact" className="nav-link p-0 ">
                        {lang === "ar" ? "تواصل معنا" : "Contact"}
                      </Link>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-between flex-wrap">
              <div className="col-12 col-md-9 mb-3">
                <h5>{lang === "ar" ? "ابقى على تواصل" : "Stay In touch"}</h5>
                <ul className="nav flex-column gap-4 m-0  p-0">
                  <li className="nav-item mb-2 mt-2">
                    {footer?.data?.[0]?.phone?.length > 0 && (
                      <h4 className="nav-link p-0 text-white">
                        {lang === "ar" ? "رقم الهاتف" : "Phone"} :{" "}
                        {footer?.data?.[0]?.phone}
                      </h4>
                    )}
                    {footer?.data?.[0]?.email?.length > 0 && (
                      <h4 className="nav-link p-0 text-white">
                        {lang === "ar" ? "البريد الإلكتروني" : "Email"} :
                        {footer?.data?.[0]?.email}
                      </h4>
                    )}
                  </li>
                </ul>
              </div>
              <div className="d-flex align-items-center col-md-2 col-12 gap-2">
                {footer?.data?.[0]?.instagram?.length > 0 && (
                  <p className="nav-item mb-2 ms-0">
                    <a
                      href={footer?.data?.[0]?.instagram}
                      target="balnk"
                      className="nav-link p-0 text-white fs-3"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </p>
                )}

                {footer?.data?.[0]?.facebook?.length > 0 && (
                  <p className="nav-item mb-2 ms-0">
                    <a
                      href={footer?.data?.[0]?.facebook}
                      target="balnk"
                      className="nav-link p-0 text-white fs-3"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </p>
                )}
              </div>
            </div>
          </footer>
        </Container>
      </div>
      <div className="w-100 text-center ">
        <p className="bg-custom-grey m-0 py-2">
          © 2024 Copyright:{" "}
          <a
            href="https://mysajda.com/"
            className="text-decoration-none primary"
          >
            {" "}
            mysajda.com
          </a>
        </p>
      </div>
    </>
  );
}
