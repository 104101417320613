import { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Lang } from "../../../Context/LangContext";
import Loading from "../../../Components/Loading//Loading";
import { Axios } from "../../../Api/axios";
import {
  CHECKCOUPON,
  CHECKOUT,
  COUPON,
  CheckQuantity,
  PaymentTest,
} from "../../../Api/api";
import NavBar from "../../../Components/Web/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import AreYouSure from "../../../Components/Dashboard/AreYouSure/AreYouSure";
import { Cart } from "../../../Context/CartContext";
import LoadingBtn from "../../../Components/Loading/LoadingBtn/LoadingBtn";
import "./checkout.css";
export default function Checkout() {
  const [form, setForm] = useState({
    name: "",
    surename: "",
    email: "",
    mobile_number: "",
    coupon: "",
    country: "",
    city: "",
    full_address: "",
    currency: "",
    subscribe: true,
  });
  const [productIds, setProductIds] = useState([]);
  const [getProducts, setGetProducts] = useState([]);
  const { total, getData } = useContext(Cart);
  const [colorIds, setColorIds] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [checkQuantities, setQuantities] = useState([]);
  const [flag, setFlag] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [couponMessage, setCouponeMessage] = useState("");
  const [errorCouponeMessage, setErrorCouponeMessage] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [payment, setPayment] = useState(false);
  const [urlPayment, setUrlPayment] = useState("");

  const [loading, setLoading] = useState(false);
  // Lang
  const language = useContext(Lang);
  const lang = language.lang;
  const typography = lang === "ar" ? "f-cairo" : "f-work-sans";

  // Handle Change Input
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
    setFlag(false);
  }
  // Handle Get Data From LocalStorage
  useEffect(() => {
    setProductIds(JSON.parse(localStorage.getItem("productsIds")));
    setColorIds(JSON.parse(localStorage.getItem("colorIds")));
    setQuantity(JSON.parse(localStorage.getItem("quantity")));
  }, []);

  // Handle Get Coupon
  async function getCoupon() {
    try {
      const res = await Axios.post(`${COUPON}/${CHECKCOUPON}`, form);
      if (res?.data?.discount) {
        setCouponeMessage(res?.data?.discount);

        setErrorCouponeMessage("");
      } else {
        setErrorCouponeMessage(res?.data?.message);
        setCouponeMessage("");
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (form.coupon !== "") {
      const debounce = setTimeout(() => {
        getCoupon();
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [form.coupon]);
  useEffect(() => {
    setGetProducts(
      localStorage.getItem("products")
        ? JSON.parse(localStorage.getItem("products"))
        : []
    );
  }, [getData]);

  useEffect(() => {
    if (form.coupon !== "") {
      const debounce = setTimeout(() => {
        getCoupon();
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [form.coupon]);

  // Handle Submit
  async function handleSubmit(e, newUrl) {
    const quantitiesCheck = [];
    let flag = true;
    for (let i = 0; i < getProducts.length; i++) {
      const res = await Axios.get(`check-quantity/${colorIds[i]}`);
      quantitiesCheck.push(res.data.quantity);
    }
    setQuantities(quantitiesCheck);
    getProducts.some((item, key) => {
      if (item.quantity > quantitiesCheck[key]) {
        flag = false;
        return true;
      }
    });
    if (flag === true) {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      formData.append("name", form.name);
      formData.append("surename", form.surename);
      formData.append("mobile_number", form.mobile_number);
      formData.append("email", form.email);
      formData.append("country", lang === "ar" ? "قطر" : "Qatar");
      formData.append("city", form.city);
      formData.append("full_address", form.full_address);
      formData.append("coupon", form.coupon);
      formData.append("currency", "DOLLAR");
      formData.append("lang", lang);
      for (let i = 0; i < productIds?.length; i++) {
        formData.append("product_id[]", productIds[i]);
      }
      for (let i = 0; i < colorIds?.length; i++) {
        formData.append("color_id[]", colorIds[i]);
      }
      for (let i = 0; i < quantity?.length; i++) {
        formData.append("quantity[]", quantity[i]);
      }
      try {
        if (emailRegex.test(form.email) && form.mobile_number.length === 8) {
          const res = await Axios.post(newUrl, formData);
          localStorage.removeItem("products");
          localStorage.removeItem("productsIds");
          localStorage.removeItem("colorIds");
          localStorage.removeItem("quantity");
          if (newUrl === PaymentTest) {
            setUrlPayment(res.data);
            window.location.href = res.data;
            setLoading(false);
          }
          if (newUrl === CHECKOUT) {
            setLoading(false);
            setShowPopUp(true);
          }
        } else {
          setFlag(true);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    } else {
      window.scroll(0, 0);
    }
  }
  // Handle OnClick Button Payment
  async function handleClickButton(e) {
    if (!payment) {
      const url = PaymentTest;
      await handleSubmit(e, url)
        .then(console.log(urlPayment))
        .catch((err) => console.log(err));
    } else {
      const url = CHECKOUT;
      await handleSubmit(e, url)
        .then(console.log(urlPayment))
        .catch((err) => console.log(err));
    }
  }

  // Handle scroll in input
  const inputRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef.current;

    inputElement.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      inputElement.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <>
      <NavBar />
      <AreYouSure
        checkout
        title={
          lang === "en"
            ? "Your request has been received. We will contact you soon"
            : "تم استلام طلبك سنتواصل معك قريبا"
        }
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
      />
      <div
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
        className={`bg-custom-grey ${typography}`}
      >
        <Container>
          <div dir={lang === "en" ? "ltr" : "rtl"} className="bg-white p-5">
            <p className="fs-2 fw-bold">
              {lang === "en" ? "Checkout" : "الدفع "}
            </p>
            <div className="mb-3 col-md-5 col-12">
              <p className="m-0">
                {lang === "en" ? "Pay My Sajda" : "دفع سجدتي"}
              </p>
              <div className="d-flex align-items-center gap-3">
                <p className="display-6 position-relative">
                  {form.coupon !== "" && couponMessage !== "" && (
                    <span className="span"></span>
                  )}
                  {lang === "en" ? `QR${total}` : `${total}ر.ق`}
                </p>
                <p className="display-6">
                  {form.coupon !== "" &&
                    couponMessage !== "" &&
                    (lang === "en"
                      ? `QR${total * ((100 - couponMessage) / 100)}`
                      : `${total * ((100 - couponMessage) / 100)}ر.ق`)}
                </p>
              </div>
              {getProducts.map((item, key) => (
                <div key={key}>
                  <div className="d-flex align-items-center justify-content-between mb-0">
                    <p className="m-0">
                      {lang === "en" ? item.title_en : item.title_ar}
                    </p>
                    <p className="m-0">
                      {lang === "en" ? `Qr${item.price}` : `${item.price}ر.ق`}
                    </p>
                  </div>
                  <p style={{ fontSize: "12px", color: "#787D82" }}>
                    {lang === "en"
                      ? `Qyt ${item.quantity}`
                      : `الكمية ${item.quantity}`}
                    <span className="px-3 fs-6" style={{ color: "red" }}>
                      {checkQuantities.length > 0 &&
                      checkQuantities[key] < item.quantity
                        ? lang === "ar"
                          ? "الكمية المتاحة هي " + checkQuantities[key]
                          : "Quantity available is " + checkQuantities[key]
                        : ""}
                    </span>
                  </p>
                </div>
              ))}
            </div>
            <p className="fs-2 fw-bold">
              {lang === "en"
                ? "Payment and shipping information"
                : "معلومات الدفع و الشحن"}
            </p>
            <div>
              <Form onSubmit={(e) => handleSubmit(e, CHECKOUT)}>
                <div className="d-flex align-items-center justify-content-between flex-wrap ">
                  <div className="mb-3 col-12 col-md-6">
                    <Form.Group
                      className={`${
                        lang === "en" ? "me-md-3 me-0" : "ms-md-3 ms-0"
                      }`}
                      controlId="formBasicFirstName"
                    >
                      <Form.Label>
                        {lang === "en" ? "First Name :" : "الأسم الأول :"}
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleChange}
                        type="text"
                        placeholder={
                          lang === "en"
                            ? "Write First Name "
                            : "ادخل الأسم الأول  "
                        }
                        value={form.name}
                        name="name"
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <Form.Group controlId="formBasicLastName">
                      <Form.Label>
                        {lang === "en" ? " Surname :" : "الأسم الأخير :"}
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleChange}
                        type="text"
                        placeholder={
                          lang === "en"
                            ? "Write Last Name "
                            : "ادخل الأسم الأخير  "
                        }
                        value={form.surename}
                        name="surename"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap ">
                  <div className="mb-3 col-12 col-md-6 position-relative">
                    <Form.Group
                      className={`${
                        lang === "en" ? "me-md-3 me-0" : "ms-md-3 ms-0"
                      }`}
                      controlId="formBasicMobile"
                    >
                      <Form.Label>
                        {lang === "en" ? "Mobile Number :" : " رقم الهاتف :"}
                      </Form.Label>
                      <Form.Control
                        required
                        inputmode="numeric"
                        pattern="[0-9]*"
                        onChange={handleChange}
                        ref={inputRef}
                        type="number"
                        placeholder={
                          lang === "en"
                            ? "Write Mobile Number "
                            : "ادخل رقم الهاتف "
                        }
                        value={form.mobile_number}
                        name="mobile_number"
                      />
                    </Form.Group>
                    {flag && form?.mobile_number?.length !== 8 && (
                      <p
                        className={`position-absolute top-100 ${
                          lang === "en" ? "start-0" : "end-0"
                        } text-danger`}
                      >
                        {lang === "en"
                          ? "Please enter an eight-digit phone number"
                          : " الرجاء ادخال رقم هاتف مكون من 8 خانات"}
                      </p>
                    )}
                  </div>
                  <div className="mb-3 col-12 col-md-6 position-relative">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        {lang === "en" ? "Email :" : "البريد الإلكتروني :"}
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleChange}
                        type="email"
                        placeholder={
                          lang === "en"
                            ? "Write Email "
                            : "ادخل البريد الإلكتروني"
                        }
                        value={form.email}
                        name="email"
                      />
                    </Form.Group>
                    {flag && !emailRegex.test(form.email) && (
                      <p
                        className={`position-absolute top-100 ${
                          lang === "en" ? "start-0" : "end-0"
                        } text-danger`}
                      >
                        {lang === "en"
                          ? "please enter a working email address"
                          : " الرجاء إدخال بريد الكتروني صحيح"}
                      </p>
                    )}
                  </div>
                </div>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicCoupon"
                >
                  <Form.Label>
                    {lang === "en"
                      ? "Coupon code (if available) :"
                      : " كود الحسم (اذا وجد) :"}
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    type="text"
                    placeholder={
                      lang === "en" ? "Write Coupon code " : "ادخل كود الحسم"
                    }
                    value={form.coupon}
                    name="coupon"
                  />
                  {form.coupon !== "" && couponMessage !== "" && (
                    <p
                      className={`position-absolute top-100 ${
                        lang === "en" ? "start-0" : "end-0"
                      } text-success`}
                    >
                      {lang === "en"
                        ? `The discount value is: ${couponMessage}%`
                        : `إن قيمة الحسم هي ${couponMessage}%`}
                    </p>
                  )}
                  {form.coupon !== "" && errorCouponeMessage !== "" && (
                    <p
                      className={`position-absolute top-100 ${
                        lang === "en" ? "start-0" : "end-0"
                      } text-danger`}
                    >
                      {lang === "en"
                        ? `Coupon not exists`
                        : `الكوبون غير موجودة`}
                    </p>
                  )}
                </Form.Group>
                <div className="d-flex align-items-center justify-content-between flex-wrap ">
                  <div className="mb-3 col-12 col-md-6">
                    <Form.Group
                      className={`${
                        lang === "en" ? "me-md-3 me-0" : "ms-md-3 ms-0"
                      }`}
                      controlId="formBasicCountry"
                    >
                      <Form.Label>
                        {lang === "en" ? "Country  :" : " الدولة :"}
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleChange}
                        type="text"
                        placeholder={
                          lang === "en" ? "Write Country  " : "ادخل اسم الدولة"
                        }
                        value={lang === "en" ? "Qatar" : "قطر"}
                        name="country"
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <Form.Group controlId="formBasicCity">
                      <Form.Label>
                        {lang === "en" ? "City :" : " المدينة :"}
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleChange}
                        type="text"
                        placeholder={
                          lang === "en" ? "Write City " : "ادخل اسم المدينة"
                        }
                        value={form.city}
                        name="city"
                      />
                    </Form.Group>
                  </div>
                </div>

                <Form.Group className="mb-3" controlId="formBasicAddress">
                  <Form.Label>
                    {lang === "en" ? "Full address :" : " العنوان الكامل :"}
                  </Form.Label>
                  <Form.Control
                    required
                    onChange={handleChange}
                    type="text"
                    placeholder={
                      lang === "en"
                        ? "Write Full address   "
                        : "ادخل العنوان الكامل"
                    }
                    value={form.full_address}
                    name="full_address"
                  />
                </Form.Group>
                {form.coupon !== "" && couponMessage && (
                  <div className="d-flex align-items-center gap-3">
                    <p className="display-6">
                      {lang === "en"
                        ? `Total after discount : QR${
                            total * ((100 - couponMessage) / 100)
                          }`
                        : `المجموع بعد الخصم : ر.ق${
                            total * ((100 - couponMessage) / 100)
                          }`}
                    </p>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between my-3">
                  <div className="d-flex align-items-center gap-3 my-1">
                    <input
                      type="checkbox"
                      value={payment}
                      id="payment"
                      onChange={() => setPayment((prev) => !prev)}
                    />
                    <label htmlFor="payment">
                      {lang === "en"
                        ? "Payment on delivery"
                        : "الدفع عند التوصيل"}
                    </label>
                  </div>
                </div>
                {!payment && (
                  <p className="text-danger px-3 rounde mb-3">
                    {lang === "en"
                      ? "You will be directed to a secure payment page"
                      : "سيتم توجيهك الى صفحة دفع أمنة"}
                  </p>
                )}
                <div
                  className={`position-relative rounded-pill bg-custom-red py-2 text-capitalize ${typography}  text-center text-white add-to-cart`}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  <p
                    style={{ fontSize: "22px" }}
                    className="m-0"
                    onClick={handleClickButton}
                  >
                    {loading ? (
                      <LoadingBtn />
                    ) : payment ? (
                      lang === "ar" ? (
                        "اطلب الآن"
                      ) : (
                        "Order now"
                      )
                    ) : lang === "ar" ? (
                      "ادفع الآن"
                    ) : (
                      "Pay now"
                    )}
                  </p>
                </div>

                {/* <div className="d-flex align-items-center gap-3 my-3">
                  <input
                    type="checkbox"
                    id="sub"
                    value={form.subscribe}
                    onChange={() => {
                      setForm((prev) => ({
                        ...prev,
                        subscribe: !prev.subscribe,
                      }));
                    }}
                    checked={form.subscribe}
                  />
                  <label htmlFor="sub">
                    {lang === "en"
                      ? "Subscribe To Our Newsletter"
                      : "الأشتراك بالقائمة البريدية"}
                  </label>
                </div> */}
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
