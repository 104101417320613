import Product from "./Product";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { PRO } from "../../../Api/api";
import { Axios } from "../../../Api/axios";
import Skeleton from "react-loading-skeleton";
import { Lang } from "../../../Context/LangContext";
import { useContext } from "react";

export default function Products() {
  const { isLoading, data: products } = useQuery({
    queryFn: () => Axios.get(`${PRO}`),
    queryKey: ["homeProducts"],
    staleTime: Infinity,
  });
  const { lang } = useContext(Lang);
  const typography = lang === "ar" ? "f-cairo" : "f-pop";

  const productsShow = products?.data?.map((product, key) => (
    <Product
      key={key}
      id={product.id}
      title={product.title_en}
      title_ar={product.title_ar}
      description={product.description_en}
      description_ar={product.description_ar}
      img={product.colors[0]?.url}
      price={product.price}
      discount={product.discount}
      quantity={product.quantity}
      colors={product.colors}
      className="col-lg-3 col-xl-3 col-sm-5 col-9"
    />
  ));

  return (
    <Container>
      <h1 className={`text-center text-uppercase mb-5 ${typography}`}>
        {lang === "en" ? "Choose your product" : " اختر منتجك الآن"}
      </h1>
      <div className="d-flex justify-content-center justify-content-sm-evenly align-items-stretch flex-wrap column-gap-5 row-gap-6">
        {isLoading ? (
          <div className="d-flex flex-wrap gap-2">
            <Skeleton height={"300px"} width={"200px"} />
            <Skeleton height={"300px"} width={"200px"} />
            <Skeleton height={"300px"} width={"200px"} />
            <Skeleton height={"300px"} width={"200px"} />
          </div>
        ) : (
          productsShow
        )}
      </div>
    </Container>
  );
}
